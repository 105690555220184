import { useMemo } from "react";
import { ChartContainer } from '@mui/x-charts';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import styles from "./css/KPIHistory.module.css";

const KPIHistory = ({ kpiHistory = [], kPIHistoryContainerBoxSizing }) => {
  const kPIHistoryContainerStyle = useMemo(() => ({
    boxSizing: kPIHistoryContainerBoxSizing,
  }), [kPIHistoryContainerBoxSizing]);

  // Handle no values or less than 5 values
  const pData = Array.isArray(kpiHistory) && kpiHistory.length > 0 ? kpiHistory : [0]; // Provide a default value if empty
  const xLabels = pData.map((_, index) => `Data ${index + 1}`);
  const minY = pData.length > 0 ? Math.min(...pData) : 0; // Set to 0 if empty
  const maxY = pData.length > 0 ? Math.max(...pData) : 0; // Set to 0 if empty

  const chartsParams = {
    margin: { bottom: 5, top: 5, left: 5, right: 5 },
    height: 60,
    width: 150
  };
  
  return (
    <div
      className={styles.kpiHistoryContainer}
      style={kPIHistoryContainerStyle}
    >
      {pData.length > 0 ? ( // Render ChartContainer only if there are data points
        <ChartContainer
          {...chartsParams}
          series={[{ type: 'line', data: pData }]}
          xAxis={[{ scaleType: 'point', data: xLabels }]}
          yAxis={[
            { id: 'leftAxis', min: minY, max: maxY}
          ]}
          sx={{
            '.MuiLineElement-root': {
              stroke: '#8884d8',
              strokeWidth: 2,
            },
            '.MuiMarkElement-root': {
              stroke: '#8884d8',
              scale: '0.6',
              fill: '#fff',
              strokeWidth: 2,
            },
          }}
          disableAxisListener
        >
          <LinePlot />
          <MarkPlot />
        </ChartContainer>
      ) : (
        <div>No KPI history data available</div> // Provide a fallback UI for no data
      )}
    </div>
  );
};

export default KPIHistory;
