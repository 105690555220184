import styles from "./css/CompanyID.module.css";
const CompanyID = ({
  companyName,
  companyTagline,
}) => {
  return (
    <div className={styles.companyId}>
      <b className={styles.awesocoInc}>{companyName}</b>
      <div className={styles.solvingProblemsIn}>{companyTagline}</div>
    </div>
  );
};

export default CompanyID;
