import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styles from "./css/ObjectiveDetailsComponent.module.css";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import { updateObjectiveAsync, deleteObjectiveAsync } from '../redux/slices/objectivesSlice';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';

const ObjectiveDetailsComponent = ({ objective, onClose }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editableName, setEditableName] = useState(objective.name);
  const [editableDescription, setEditableDescription] = useState(objective.description);
  const { kpis = [], isLoading, error } = useSelector(state => state.kpis) || {};
  const kpisInSegment = kpis.filter(item => item.businesssegment === objective.businesssegment);

  const [selectedKpis, setSelectedKpis] = useState(kpis.filter(kpi => (objective.kpis || []).includes(kpi.id)));

  const handleChange = (event, value) => {
    setSelectedKpis(value);
    const updatedData = {
      id: objective.id,
      name: objective.name,
      description: objective.description,
      kpis: value.map(kpi => kpi.id),
      projects: objective.projects,
      dashboard: objective.dashboard,
      businesssegment: objective.businesssegment,      
    };
    dispatch(updateObjectiveAsync({ objectiveId: objective.id, updatedData: updatedData}));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Dispatch update action here
    const updatedData = {
      id: objective.id,
      name: editableName,
      description: editableDescription,
      kpis: objective.kpis,
      projects: objective.projects,
      dashboard: objective.dashboard,
      businesssegment: objective.businesssegment,      
    };
    dispatch(updateObjectiveAsync({ objectiveId: objective.id, updatedData: updatedData}));
  };

  const handleCancelClick = (event) => {
    // Revert changes
    event.stopPropagation();
    setEditableName(objective.name);
    setEditableDescription(objective.description);

    // Exit editing mode
    setIsEditing(false);
  };

  const handleNameChange = (event) => {
    setEditableName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditableDescription(event.target.value);
  };

  const handleDeleteClick = () => {
    // Delete the objective
    dispatch(deleteObjectiveAsync(objective.id));
  };
  
  const handleMainDivClick = (event) => {
    event.stopPropagation();
  };

  const handleClose = () => {
    onClose();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.objectiveDetailsComponent} onClick={handleMainDivClick}>
      <div className={styles.objectiveDetailsHeader}>
        {isEditing ? (
          <div className={styles.editableFields}>
            <TextField
              label="Objective Name"
              variant="outlined"
              value={editableName}
              onChange={handleNameChange}
              onClick={(e) => e.stopPropagation()}
              fullWidth
            />
            <br /><br></br>
            <TextField
              label="Objective Description"
              variant="outlined"
              value={editableDescription}
              onChange={handleDescriptionChange}
              multiline
              rows={4}
              fullWidth
            />
            <br /><br/>
            <Box display="flex" justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={handleSaveClick} startIcon={<CheckIcon />}>
                Save
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancelClick} startIcon={<ClearIcon />}>
                Cancel
              </Button>
            </Box>
          </div>
        ) : (
          <div className={styles.nonEditableFields}>
            <h2>{objective.name}</h2>
            <p>{objective.description}</p>
            <Button variant="outlined" color="primary" onClick={handleEditClick} startIcon={<EditIcon />}>
              Edit
            </Button>
            
          </div>
        )}
      </div>
      <div className={styles.kpiDetailsComponentInner}>
        <div className={styles.frameWrapper}>
          <Box display="flex" justifyContent="space-between">
            <Autocomplete
              multiple
              id="checkboxes-kpis-objective"
              options={kpisInSegment}
              disableCloseOnSelect
              getOptionLabel={(option) => `${option.name} (${option.id})`}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <ListItemText primary={`${option.name} (${option.id})`} />
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Driving KPIs" placeholder="Driving KPIs" />
              )}
              value={selectedKpis || []}
              onChange={handleChange}
            />
            
          </Box>
        </div>
      </div>
      <br></br>
      <Box display="flex" justifyContent="space-between">
        <Button variant="outlined" color="secondary" onClick={handleDeleteClick} startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose} startIcon={<CancelIcon />}>
          Close
        </Button>
      </Box>
    </div>
  );
};

export default ObjectiveDetailsComponent;
