import { Button, TextField, Dialog, DialogContent, Paper, Typography, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AddGoalDialog = ({ open, onClose, onAddGoal, onEditGoal, onDeleteGoal, initialGoalData }) => {
  const [name, setName] = useState(initialGoalData?.name || '');
  const [description, setDescription] = useState(initialGoalData?.description || '');
  const [notes, setNotes] = useState(initialGoalData?.notes || '');

  useEffect(() => {
    setName(initialGoalData?.name || '');
    setDescription(initialGoalData?.description || '');
    setNotes(initialGoalData?.notes || '');
  }, [initialGoalData]);

  const handleAddOrEdit = (event) => {
    event.preventDefault();
    const goalData = { name, description, notes };
    if (initialGoalData) {
        onEditGoal(goalData);
    } else {
        onAddGoal(goalData);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{ padding: '32px', marginTop: 64, marginBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            {initialGoalData ? 'Edit Goal' : 'Add Goal'}
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Typography>
          <DialogContent>
            <form onSubmit={handleAddOrEdit} style={{ width: '100%', marginTop: '8px' }}>
              <TextField 
                fullWidth 
                margin="normal" 
                variant="outlined" 
                label="Goal Name" 
                value={name} 
                required
                onChange={(e) => setName(e.target.value)} 
              />
              <TextField 
                fullWidth 
                margin="normal" 
                variant="outlined" 
                label="Goal Description" 
                value={description} 
                required
                onChange={(e) => setDescription(e.target.value)} 
              />
              <TextField 
                fullWidth 
                margin="normal" 
                variant="outlined" 
                label="Notes" 
                value={notes} 
                onChange={(e) => setNotes(e.target.value)} 
              />
              <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Button type="submit" fullWidth variant="contained" color="primary" style={{ margin: '15px' }}>
                  {initialGoalData ? 'Save' : 'Add'}
                </Button>
                <Button variant="contained" fullWidth color="secondary" onClick={onClose} style={{ margin: '15px' }}>
                  Cancel
                </Button>
              </div>
              {initialGoalData && (
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Button 
                      fullWidth 
                      variant="contained" 
                      color="primary" 
                      onClick={() => { 
                          onDeleteGoal(initialGoalData.id); 
                          onClose(); 
                      }} 
                      style={{ margin: '15px' }}
                  >
                    Delete Goal
                  </Button>
                </div>
              )}
            </form>
          </DialogContent>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default AddGoalDialog;