import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchObjectives, addObjective, updateObjective, deleteObjective } from '../../api/objectivesAPI';

export const fetchObjectivesAsync = createAsyncThunk(
    'objectives/fetchObjectives',
    async (businesssegment_id , { rejectWithValue }) => {
        try {
            const response = await fetchObjectives(businesssegment_id);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addObjectiveAsync = createAsyncThunk(
    'objectives/addObjective',
    async (newObjective, { rejectWithValue }) => {
        try {
            const response = await addObjective(newObjective);
            const data = await response;
            return data; // Ensure the new Objective is returned
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateObjectiveAsync = createAsyncThunk(
    'objectives/updateObjective',
    async ({ objectiveId, updatedData }) => {
        const updatedObjective = await updateObjective(objectiveId, updatedData);
        return { objectiveId, updatedObjective };
    }
);

export const deleteObjectiveAsync = createAsyncThunk(
    'objectives/deleteObjective',
    async (objectiveId, { rejectWithValue }) => {
        try {
            await deleteObjective(objectiveId);
            return objectiveId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    objectives: [],
    isLoading: false,
    error: null,
};

const objectivesSlice = createSlice({
    name: 'objectives',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchObjectivesAsync.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchObjectivesAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                const newObjectives = action.payload;
                const combinedObjectives = [...state.objectives, ...newObjectives];
                // Eliminate duplicates
                state.objectives = combinedObjectives.filter((objective, index, self) =>
                index === self.findIndex((t) => t.id === objective.id)
                );
            })
            .addCase(fetchObjectivesAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addObjectiveAsync.fulfilled, (state, action) => {
                state.objectives.push(action.payload);
            })
            .addCase(addObjectiveAsync.rejected, (state, action) => {
                // Handle the error
            })
            .addCase(updateObjectiveAsync.fulfilled, (state, action) => {
                const { objectiveId, updatedObjective } = action.payload;
                const existingObjective = state.objectives.find(objective => objective.id === objectiveId);
                if (existingObjective) {
                    Object.assign(existingObjective, updatedObjective);
                }
            })
            .addCase(deleteObjectiveAsync.fulfilled, (state, action) => {
                state.objectives = state.objectives.filter(objective => objective.id !== action.payload);
            });
    },
});

export default objectivesSlice.reducer;