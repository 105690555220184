import { useMemo } from "react";
import styles from "./css/SegmentObjectiveStatus.module.css";
const SegmentObjectiveStatus = ({ objectiveStatusColor }) => {
  const objectiveStatusStyle = useMemo(() => {
    return {
      backgroundColor: objectiveStatusColor,
    };
  }, [objectiveStatusColor]);

  return (
    <div className={styles.objectiveStatus} style={objectiveStatusStyle} />
  );
};

export default SegmentObjectiveStatus;
