import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addKPIHeadingAsync, deleteKPIHeadingAsync, updateKPIHeadingAsync } from '../redux/slices/kpiHeadingsSlice';
import {fetchKPIsAsync, addNewKPIAsync, fetchKPIDataAsync} from '../redux/slices/kpiSlice';
import KPI from "./KPI";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import styles from "./css/KPIHeading.module.css";
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomAccordion = styled(Accordion)({
  width: '100%',
  '& .MuiCollapse-root': {
    marginBottom: '10px',
  },
  '& .MuiAccordionDetails-root': {
    paddingBottom: '10px',
  },
});

const CustomAccordionSummary = styled(AccordionSummary)({
  '&.MuiAccordionSummary-root': {
    minHeight: 'auto',
    padding: 0,
    margin: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: 0,
    },
  },
});

const AddNewKPI = () => {
  return (
    <div className={styles.segmentDataContainer_add}>
      <AddIcon />
    </div>
  );
};


const KPIHeadingContainer = ({ isAddingNew, onCancel, onSegmentSaved, activeDashboard, businesssegmentId, kpiHeadingObject }) => {

  const [newHeadingName, setNewHeadingName] = useState(kpiHeadingObject ? kpiHeadingObject.name : 'New KPI Heading');
  const dispatch = useDispatch();
  const { kpis: allKpis = [], isLoading, error } = useSelector(state => state.kpis) || {};
  const kpis_array = kpiHeadingObject 
    ? allKpis.filter(kpi => kpi.kpiheading === kpiHeadingObject.id)
    : [];
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For menu
  const open = Boolean(anchorEl);

  const isKPIDue = (kpi) => {
    if (!kpi.kpiData || kpi.kpiData.length === 0) {
      return false;
    }
  
    const latestKPIDate = new Date(kpi.kpiData[kpi.kpiData.length - 1].date_created);
    const frequency = kpi.frequency;
    const nextDueDate = new Date(latestKPIDate);
  
    switch (frequency) {
      case 'Weekly':
        nextDueDate.setDate(nextDueDate.getDate() + 7);
        break;
      case 'Monthly':
        nextDueDate.setMonth(nextDueDate.getMonth() + 1);
        break;
      case 'Quarterly':
        nextDueDate.setMonth(nextDueDate.getMonth() + 3);
        break;
      // Add more cases as per your frequency types
      default:
        break;
    }
  
    return nextDueDate <= new Date();
  };

  const countDueKPIs = kpis_array.reduce((count, kpi) => {
    return count + (isKPIDue(kpi) ? 1 : 0);
  }, 0);

  const countNotDueKPIs = kpis_array.length - countDueKPIs;
  

  useEffect(() => {
    if (kpiHeadingObject && kpiHeadingObject.id) {
      dispatch(fetchKPIsAsync(kpiHeadingObject.id)).then((action) => {
        if (fetchKPIsAsync.fulfilled.match(action)) {
          action.payload.forEach(kpi => {
            dispatch(fetchKPIDataAsync(kpi.id));
          });
        }
      });
    }
  }, [dispatch, kpiHeadingObject]);

  useEffect(() => {
    if (isAddingNew) {
      setIsEditing(true);
    }
  }, [isAddingNew]);

  const handleSave = () => {
    if (isEditing) {
      if (kpiHeadingObject && kpiHeadingObject.id) {
        setIsEditing(false);
        dispatch(updateKPIHeadingAsync({ kpiHeadingId: kpiHeadingObject.id, updatedData: { 
          name: newHeadingName,
          description: kpiHeadingObject.description,
          notes: kpiHeadingObject.notes,
          score: kpiHeadingObject.score,
          dashboard: kpiHeadingObject.dashboard,
          businesssegment: kpiHeadingObject.businesssegment
        } }));
      } else if (isAddingNew) {
        const newKPIHeading = {
          name: newHeadingName,
          description: 'New KPI Heading description',
          notes: 'New KPI Heading notes',
          score: 0,
          dashboard: activeDashboard.id,
          businesssegment: businesssegmentId,
        }
        onSegmentSaved();
        dispatch(addKPIHeadingAsync(newKPIHeading));
      }
    } else {
      setIsEditing(true);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this KPI Heading?")) {
      try {
        await dispatch(deleteKPIHeadingAsync(kpiHeadingObject.id));
      } catch (error) {
        console.error("Failed to delete KPI Heading:", error);
      }
    }
  };

  const handleNameChange = (event) => {
    setNewHeadingName(event.target.value);
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (isAddingNew) {
      onCancel();
    }
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  // Function to handle the addition of a new KPI
  const handleAddNewKPI = () => {
    // Define the structure for a new KPI based on your backend model
    const newKPIData = {
      name: 'New KPI',
      description: 'new KPI description',
      frequency: 'Weekly', // Default value, or make it selectable
      min_value: 0, // Default value
      max_value: 100, // Default value
      unit: '%', // Default value
      dashboard: activeDashboard.id,
      kpiData: [],
      kpiheading: kpiHeadingObject.id,
      businesssegment: kpiHeadingObject.businesssegment,
    };
    
    // Dispatch the action to add a new KPI
    dispatch(addNewKPIAsync(newKPIData));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
      return <div>Error: {error}</div>;   
  }

  return (
    <CustomAccordion className={styles.kpiHeadingContainer}>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header" 
          className={styles.kpiSection}  
        >
          {isEditing ? (
            <>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
              <div className={styles.kpiHeadingHeader_edit}>
                <Input
                  disableUnderline
                  onClick={(event) => { event.stopPropagation();}}
                  className={styles.kpiName}
                  value={newHeadingName}
                  onChange={handleNameChange}
                  endAdornment={
                    <>
                      <IconButton onClick={handleSave}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={handleCancel}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  }
                />
              </div>
            </>
          ) : (
            <>
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
                onClick={(event) => { event.stopPropagation();}}
              >
                <MenuItem onClick={(event) => { event.stopPropagation(); setIsEditing(true); handleMenuClose(); }}>
                  <EditIcon /> Edit
                </MenuItem>
                <MenuItem onClick={(event) => { event.stopPropagation(); handleDelete(); handleMenuClose(); }}>
                  <DeleteIcon /> Delete
                </MenuItem>
              </Menu>
              <div className={styles.kpiHeadingHeader}>
                <div className={styles.kpiName}>{kpiHeadingObject && kpiHeadingObject.name}</div>
              </div>
              <div className={styles.kpiDues}>
                <b className={styles.dueStyle} title='KPIs due for an update' > {countDueKPIs}</b>
                <ErrorIcon className={styles.dueStyleIcon}/> 
                <b className={styles.dueStyle} >/</b>
                <b className={styles.notDueStyle} title='KPIs up-to-date' > {countNotDueKPIs}</b>
                <CheckCircleIcon className={styles.notDueStyleIcon}/>
              </div>
            </>

          )}
        </CustomAccordionSummary>
      <AccordionDetails className={styles.kpiHeadingContainer}>
          <div className={styles.kpiSection}>
            <div className={styles.kpisContainer}>
              {kpis_array && kpis_array.map(kpi => {
                return (
                  <KPI
                    key={kpi.id}
                    kpi_object={kpi}
                  />
                );
              })}
              <div onClick={handleAddNewKPI}>
                <AddNewKPI />
              </div>
            </div>
          </div>
      </AccordionDetails>
    </CustomAccordion>
    
  );
};

export default KPIHeadingContainer;