import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ObjectiveCard from "./ObjectiveCard";
import styles from "./css/BannerContainer.module.css";
import AddGoalDialog from "./AddGoalDialog";
import { fetchGoalsAsync, addGoalAsync, editGoalAsync, deleteGoalAsync } from '../redux/slices/goalsSlice';

const BannerContainer = ({ bannerContainerBackgroundImage, activeDashboard }) => {
  const dispatch = useDispatch();
  const goals = useSelector(state => state.goals.goals);
  const [editingGoal, setEditingGoal] = useState(null); 

  const handleEditClick = (goal) => {
    setEditingGoal(goal);
  };

  const handleGoalDelete = (goalId) => {
    dispatch(deleteGoalAsync(goalId));
  };

  const handleGoalUpdated = (updatedData) => {
    const dataWithDashboard = {
      ...updatedData,
      dashboard: activeDashboard.id
    };
    dispatch(editGoalAsync({id: editingGoal.id, data: dataWithDashboard}));
    setEditingGoal(null);
  };

  const handleGoalAdded = (newGoalData) => {
    const dataWithDashboard = {
      ...newGoalData,
      dashboard: activeDashboard.id
    };
    dispatch(addGoalAsync(dataWithDashboard));
  };

  useEffect(() => {
    if (activeDashboard && activeDashboard.id) {
      dispatch(fetchGoalsAsync(activeDashboard.id));
    }
  }, [dispatch, activeDashboard]);

  const bannerContainerStyle = useMemo(() => {
    return {
      backgroundImage: bannerContainerBackgroundImage,
    };
  }, [bannerContainerBackgroundImage]);

  const [isAddGoalOpen, setIsAddGoalOpen] = useState(false);

  return (
    <div id="Banner_container" className={styles.bannerContainer} style={bannerContainerStyle}>
      <div id="Objectives_container" className={styles.objectivesContainer}>
        <div id="ObjectiveCards_container" className={styles.objectivesCardsContainer}>
          {goals.map(goal => (
            <div id={"Objective_" + goal.id} key={goal.id} className={styles.goalWrapper}>
              <ObjectiveCard
                objectiveName={goal.name}
                objectiveDescription={goal.description}
                onEdit={() => handleEditClick(goal)}
                onDelete={() => handleGoalDelete(goal.id)}
              />
            </div>
          ))}
        </div>
        <div className={styles.objectiveActionsContainer}>
          <div className={styles.addObjectiveButtonContainer}>
            <IconButton onClick={() => setIsAddGoalOpen(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <AddGoalDialog
        open={isAddGoalOpen || !!editingGoal}
        onClose={() => { setIsAddGoalOpen(false); setEditingGoal(null); }}
        onAddGoal={handleGoalAdded}
        onEditGoal={handleGoalUpdated}
        onDeleteGoal={handleGoalDelete}
        initialGoalData={editingGoal}
      />
    </div>
  );
};

export default BannerContainer;