const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

export const loginUser = async ({ email, password }) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        // Handle non-200 responses here
        const errorData = await response.json();
        throw new Error(errorData.error || 'An unknown error occurred');
      }
      const data = await response.json();
      if (response.ok) {
        const accessToken = data.access; // Received from the backend
        const refreshToken = data.refresh; // Also received from the backend
        const expiresIn = 1440 * 60 * 1000; // 60 minutes in milliseconds, matching the backend
        const expirationTime = new Date().getTime() + expiresIn;
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken); // Store the refresh token
        localStorage.setItem('token_expiration', expirationTime);
        return data; // Return the entire response data
      } else {
        throw new Error(data.error || 'Login failed');
      }
    } catch (error) {
      throw new Error(error.message || 'An unknown error occurred');
    }
};
  
  // Logout User
export const logoutUser = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/logout/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ refresh: refreshToken }),
    });

    if (response.ok) {
        // The logout was successful, remove the tokens from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    } else {
        // Handle the error
        console.error('Logout failed');
    }
}

// Reset Password
export const resetPassword = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/reset-password/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: email}),
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to reset password');
    }
    return await response.json();
}

export const resetPasswordConfirm = async (userData) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/reset-password-confirm/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to reset password');
    }
    return await response.json();
}
  
  // Fetch Current User
  export const fetchCurrentUser = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/`, {
        method: 'GET',
        headers: getHeaders(),
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to fetch goals');
    }
    return await response.json();
  };

  // Update User
  export const updateUser = async (userData) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/${userData.id}/`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(userData),
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update user');
    }
    return await response.json();
  };