import React from "react";
import styles from './css/CompanyCard.module.css';

export const CompanyCard = ( { handleCompanySelected, onEdit, onEditUsers, company_obj}) => {
  return (
    <div className={styles.taskCard}>
      <div className={styles.taskframe}>
        <header className={styles.taskheader}>
          <img className={styles.istockphoto} alt="companyPhoto" src='/company1.jpg' />
        </header>
        <div className={styles.titleContainer}>
          <div className={styles.cardsTitle}>
            <div className={styles.taskTitleText}>{company_obj.name}</div>
          </div>
          <div className={styles.cardsTitle}>
            <p className={styles.textWrapper}>{company_obj.tagline}</p>
          </div>
          
        </div>
        <div className={styles.buttonsTopContainer}>
            <button className={styles.taskbutton} onClick={() => handleCompanySelected(company_obj)} >
                <div className={styles.tasklabel}>Select</div>
            </button>
            <div className={styles.buttonsContainer}>
          <button className={styles.labelWrapper} onClick={() => onEdit(company_obj)} >
            <div className={styles.tasklabel}>Edit</div>
          </button>
          <button className={styles.labelWrapper} onClick={() => onEditUsers(company_obj)} >
            <div className={styles.tasklabel}>Users</div>
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};
