import React from 'react';
import { Typography, Container, Paper } from '@mui/material';

const VerificationFailureComponent = () => {
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: 32, marginTop: 64 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Email Verification Failed
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          The verification link is invalid or expired. Please try signing up again or contact support.
        </Typography>
      </Paper>
    </Container>
  );
};

export default VerificationFailureComponent;
