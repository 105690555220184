const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

export const fetchObjectives = async (businesssegmentId ) => {
    
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/objectives/?business_segment_id=${businesssegmentId}`, {
        method: 'GET',
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch objectives.');
    }
    return await response.json();
};

export const addObjective = async (newObjectiveData) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/objectives/`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newObjectiveData),
    });
    if (!response.ok) {
        throw new Error('Failed to add objective.');
    }
    return await response.json();
};

export const updateObjective = async (objectiveId, updatedData) => {
    try {
        
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/objectives/${objectiveId}/`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Failed to update objective (A). Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to update objective (B):", error);
        throw error;
    }
};

export const deleteObjective = async (objectiveId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/objectives/${objectiveId}/`, {
            method: 'DELETE',
            headers: getHeaders(),
    });

    if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to delete objective.');
    }
};