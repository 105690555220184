import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: null,
};

export const activeDashboardSlice = createSlice({
  name: 'activeDashboard',
  initialState,
  reducers: {
    setActiveDashboard: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { setActiveDashboard } = activeDashboardSlice.actions;

export default activeDashboardSlice.reducer;