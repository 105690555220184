import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addUserToCompany, removeUserFromCompany, createCompany, fetchCompanies } from '../../api/companyAPI';

// Async thunk for fetching companies
export const fetchCompaniesAsync = createAsyncThunk(
  'companies/fetchCompanies',
  async () => {
    const companies = await fetchCompanies();
    return companies;
  }
);

export const createCompanyAsync = createAsyncThunk(
  'companies/createCompany',
  async (newCompanyData) => {
    const newCompany = await createCompany(newCompanyData);
    return newCompany;
  }
);

export const addUserToCompanyAsync = createAsyncThunk(
  'companies/addUserToCompany',
  async (companyId, email) => {
    const response = await addUserToCompany(companyId, email);
    return response;
  }
);

export const removeUserFromCompanyAsync = createAsyncThunk(
  'companies/removeUserFromCompany',
  async (companyId, userId) => {
    const response = await removeUserFromCompany(companyId, userId);
    return response;
  }
);

const initialState = {
  companies: [],
  isLoading: false,
  error: null,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaniesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompaniesAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompaniesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createCompanyAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCompanyAsync.fulfilled, (state, action) => {
        state.companies.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addUserToCompanyAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addUserToCompanyAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addUserToCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(removeUserFromCompanyAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeUserFromCompanyAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeUserFromCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { addNewCompany } = companiesSlice.actions;

export default companiesSlice.reducer;