import React, { useState, useEffect } from 'react';
import { Button, TextField, Paper, Typography, Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPasswordAsync } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Check if there is a state in the location object and set the message
    if (location.state && location.state.message) {
      setMessage(location.state.message);
      // Clear the state so it doesn't show the message again if the user navigates away and back
      location.state = null;
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(resetPasswordAsync(email));
      // if data contains error message, set message state to the error message
      if (data && data.error && data.error.message) {
        setMessage('An error occurred: ' + data.error.message);
        return;
      }
      // if response is ok, set message state to success message
      setMessage('Password reset link has been sent to your email.');

      // if data does not contain error message, redirect to dashboard and add the message to the location state
      navigate('/login', { state: { message: 'Password reset link has been sent to your email.' } });
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/login');
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '32px', marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
          <TextField 
            fullWidth 
            margin="normal"
            label="Email" 
            name="email" 
            type="email" 
            variant="outlined" 
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
            Reset Password
          </Button>
          <br></br>
          <Button fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }} onClick={handleCancel}>
            Cancel
          </Button>
          <br /><br />
          {message && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{message}</Typography>}
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
