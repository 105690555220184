import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBusinessSegments, addBusinessSegment, updateBusinessSegment, deleteBusinessSegment } from '../../api/businessSegmentAPI';

export const fetchBusinessSegmentsAsync = createAsyncThunk(
  'businessSegments/fetchBusinessSegments',
  async (dashboardId) => {
    const segments = await fetchBusinessSegments(dashboardId);
    return segments;
  }
);

export const addBusinessSegmentAsync = createAsyncThunk(
    'businessSegments/addBusinessSegment',
    async (segmentData) => {
      const newSegment = await addBusinessSegment(segmentData);
      return newSegment;
    }
);

export const updateBusinessSegmentAsync = createAsyncThunk(
    'businessSegments/updateBusinessSegment',
    async ({ segmentId, updatedData }) => {
        const updatedSegment = await updateBusinessSegment(segmentId, updatedData);
        return { segmentId, updatedSegment };
    }
);

export const deleteBusinessSegmentAsync = createAsyncThunk(
  'businessSegments/deleteSegment',
  async (segmentId, { rejectWithValue }) => {
    try {
      await deleteBusinessSegment(segmentId);
      return segmentId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
); 

const initialState = {
  businessSegments: [],
  isLoading: false,
  error: null,
};

export const businessSegmentsSlice = createSlice({
  name: 'businessSegments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessSegmentsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBusinessSegmentsAsync.fulfilled, (state, action) => {
        state.businessSegments = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchBusinessSegmentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addBusinessSegmentAsync.fulfilled, (state, action) => {
        state.businessSegments.push(action.payload);
      })
      .addCase(updateBusinessSegmentAsync.fulfilled, (state, action) => {
        const { segmentId, updatedSegment } = action.payload;
        const existingSegment = state.businessSegments.find(segment => segment.id === segmentId);
        if (existingSegment) {
          Object.assign(existingSegment, updatedSegment);
        }
      })
      .addCase(deleteBusinessSegmentAsync.fulfilled, (state, action) => {
        state.businessSegments = state.businessSegments.filter(
          (segment) => segment.id !== action.payload
        );
      });
  },
});

export default businessSegmentsSlice.reducer;
