// Create Dashboard
export const createDashboard = async (dashboardData) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/dashboards/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(dashboardData),
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to create dashboard');
    }
    return await response.json();
};

// Fetch Dashboards
export const fetchDashboards = async (companyId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/dashboards/?company_id=${companyId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to fetch dashboards');
    }
    return await response.json();
};

// Fetch Dashboard Data
export const fetchDashboardData = async (dashboardId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/dashboards/${dashboardId}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to fetch dashboard data');
    }
    return await response.json();
};

// Update Dashboard
export const updateDashboard = async (modifiedData) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/dashboards/${modifiedData.id}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(modifiedData),
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update dashboard');
    }
    return await response.json();
};

// Delete Dashboard
export const deleteDashboard = async (dashboardId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/dashboards/${dashboardId}/`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete dashboard');
    }
};