import SegmentObjective from "./SegmentObjective";
import styles from "./css/SegmentObjectiveContainer.module.css";
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchObjectivesAsync } from '../redux/slices/objectivesSlice';
import { addObjectiveAsync } from '../redux/slices/objectivesSlice';
import { calculateObjectiveProgress } from './SegmentObjective';

const AddNewSegmentObjectiveContainer = ({ activeDashboard, businessSegmentId }) => {

  const dispatch = useDispatch();

  const handleAddNewObjective = (activeDashboard, businessSegmentId) => {
    // Dispatch update action here
    const newObjective = {
      name: 'New Objective',
      description: 'New Objective Description',
      kpis: [],
      projects: [],
      dashboard: activeDashboard.id,
      businesssegment: businessSegmentId,      
    };
    dispatch(addObjectiveAsync(newObjective));
  };

  return (
    <div className={styles.segmentObjectiveContainer_add}
      onClick={() => handleAddNewObjective(activeDashboard, businessSegmentId)}
    >
      <AddIcon />
    </div>
  );
};

const SegmentObjectiveContainer = ({ activeDashboard, businessSegmentId, onSegmentPerformanceCalculated }) => {
  const dispatch = useDispatch();
  const { objectives, isLoading, error } = useSelector(state => state.objectives);
  const objectivesInSegment = objectives.filter(item => item.businesssegment === businessSegmentId);
  const { kpis: allKpis = [], isLoading_kpi, error_kpi} = useSelector(state => state.kpis) || {};
  const [segmentPerformance, setSegmentPerformance] = useState(null);

  useEffect(() => {
    if (businessSegmentId) {
      dispatch(fetchObjectivesAsync(businessSegmentId));
    }
  }, [businessSegmentId, dispatch]);

  useEffect(() => {
    
    if (!isLoading && !isLoading_kpi && objectivesInSegment.length > 0) {
      const totalProgress = objectivesInSegment.reduce((total, objective) => {
        return total + calculateObjectiveProgress(objective, allKpis);
      }, 0);
      const calculatedPerformance = Math.round(totalProgress / objectivesInSegment.length);
      setSegmentPerformance(calculatedPerformance);
    }
  }, [isLoading, isLoading_kpi, objectivesInSegment, allKpis]); 

  useEffect(() => {
    // This effect should handle notifying the parent
    if (segmentPerformance !== null && onSegmentPerformanceCalculated) {
      onSegmentPerformanceCalculated(segmentPerformance);
    }
  }, [segmentPerformance, onSegmentPerformanceCalculated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading_kpi) {
    return <div>Loading...</div>;
  }

  if (error_kpi) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.segmentObjectiveContainer}>
      <div className={styles.objectivesContainer}>
        <>
        {objectivesInSegment.map(objective_obj => (
          
          <SegmentObjective 
            key={objective_obj.id}
            objective={objective_obj}
          />
          
        ))}
        </>
        <AddNewSegmentObjectiveContainer
          activeDashboard={activeDashboard}
          businessSegmentId={businessSegmentId}
        />
      </div>
    </div>
  );
};

export default SegmentObjectiveContainer;