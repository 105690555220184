import React, { useState, useRef, useEffect } from 'react';
import styles from './css/KPIDetailData.module.css';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { deleteKPIDataAsync, updateKPIDataAsync } from '../redux/slices/kpiSlice';

const KPIDetailData = ({ kpiId, kpiDataId, kpiDataValue, kpiDataUnit, kpiDataNote, kpiDataDate, dashboard }) => {
  // State variables for editable values and notes
  const [editableValue, setEditableValue] = useState(kpiDataValue);
  const [editableNote, setEditableNote] = useState(kpiDataNote);
  const [tempValue, setTempValue] = useState(kpiDataValue);
  const [tempNote, setTempNote] = useState(kpiDataNote);
  const [isValueEditable, setIsValueEditable] = useState(false);
  const [isNoteEditable, setIsNoteEditable] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [noteChanged, setNoteChanged] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const spanRef = useRef(null);
  const dispatch = useDispatch();
  const kpiDataDate_formatted = formatDate(kpiDataDate);

  // Event handlers for value and note changes
  const handleValueChange = (e) => {
    setTempValue(e.target.value);
    setValueChanged(true);
  };

  const handleNoteChange = (e) => {
    setTempNote(e.target.value);
    setNoteChanged(true);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Replace the comma and adjust the format to match the requested format
    return formattedDate.replace(/,/, ' -');
  }

  // Confirm and cancel value and note changes
  const confirmValueChange = () => {
    setEditableValue(tempValue);
    setValueChanged(false);
    setIsValueEditable(false);
    const updatedData = {
        id: kpiDataId,
        value: tempValue,
        note: editableNote,
        date: kpiDataDate,
        kpi: kpiId,
        dashboard: dashboard,
    };
    dispatch(updateKPIDataAsync({ kpiDataId, updatedData }));
  };

  const confirmNoteChange = () => {
    setEditableNote(tempNote);
    setNoteChanged(false);
    setIsNoteEditable(false);

    const updatedData = {
        id: kpiDataId,
        value: editableValue,
        note: tempNote,
        date: kpiDataDate,
        kpi: kpiId,
        dashboard: dashboard,
    };
    dispatch(updateKPIDataAsync({ kpiDataId, updatedData }));
    setIsNoteEditable(false);
  };

  const cancelValueChange = () => {
    setTimeout(() => {
      setTempValue(editableValue);
      setIsValueEditable(false);
      setValueChanged(false);
    }, 200);
  };

  const cancelNoteChange = () => {
    setTimeout(() => {
      setTempNote(editableNote);
      setIsNoteEditable(false);
      setNoteChanged(false);
    }, 200);
  };

  // Inline styles for input fields
  const inputStyle = {
    border: 'none',
    outline: 'none',
    font: 'inherit',
    padding: '0',
  };
  const inputStyleNote = {
    border: 'none',
    outline: 'none',
    font: 'inherit',
    fontSize: 'var(--h6-16px-regular-inter-size)',
    color: 'inherit',
    padding: '2px 0px',
    width: '100%',
    lineHeight: '100%',
  };

  const handleDeleteKPIData = (kpiDataId, kpiId) => {
    // Dispatch action to delete KPI data
    dispatch(deleteKPIDataAsync({kpiDataId: kpiDataId, kpiId: kpiId}));
};

  // Handle enter and escape key presses
  const handleKeyPress = (event, type) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (type === 'value') {
        confirmValueChange();
      } else if (type === 'note') {
        confirmNoteChange();
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      if (type === 'value') {
        cancelValueChange();
      } else if (type === 'note') {
        cancelNoteChange();
      }
    }

  };

  // Set input width based on content
  useEffect(() => {
    if (spanRef.current && isValueEditable) {
      setInputWidth(spanRef.current.offsetWidth + 5);
    }
  }, [tempValue, isValueEditable]);

  // Render KPI detail data component
  return (
    <div className={styles.groupParent}>
      <div className={styles.iconWithLines}>
        <div className={styles.verticalLine}></div>
        <InsertChartOutlinedIcon className={styles.frameInner} />
        <div className={styles.verticalLine}></div>
      </div>
      <div className={styles.kpiValueParent}>
      {isValueEditable ? (
          <div className={styles.kpiValue}>
            <input
              type="text"
              autoFocus
              value={tempValue}
              onChange={handleValueChange}
              onKeyDown={(e) => handleKeyPress(e, 'value')}
              onBlur={cancelValueChange}
              style={{ ...inputStyle, width: `${inputWidth}px` }}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <span ref={spanRef} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
              {tempValue}
            </span>
            {kpiDataUnit}
              {valueChanged && <Button
                onClick={(e) => { e.preventDefault(); confirmValueChange(); }}
                size="small"
                variant="outlined"
                startIcon={<CheckIcon />}
              >Accept</Button>}

            </div>
        ) : (
          <div className={styles.kpiValue} onClick={() => {setTempValue(editableValue); setIsValueEditable(true); }}>
            {editableValue} {kpiDataUnit}
          </div>
        )}
        

        {isNoteEditable ? (
          <textarea
            autoFocus
            value={tempNote}
            onChange={handleNoteChange}
            onKeyDown={(e) => handleKeyPress(e, 'note')}
            onBlur={cancelNoteChange}
            style={{ ...inputStyleNote }}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ) : (
          <div className={styles.notes} onClick={() => {setTempNote(editableNote); setIsNoteEditable(true);}}>
            {editableNote}
          </div>
        )}
        {noteChanged && <Button
              onClick={(e) => { e.preventDefault(); confirmNoteChange(); }}
              size="small"
              variant="outlined"
              startIcon={<CheckIcon />}
            >Accept</Button>}

        <div className={styles.jul810Pm}>{kpiDataDate_formatted}</div>
      </div>
      <div className={styles.editKpiValueButtonParent}>
        <RemoveCircleOutlineIcon 
            className={styles.removeKPIDataButton} 
            onClick={() => handleDeleteKPIData(kpiDataId, kpiId)} 
        />
      </div>
    </div>
  );
};

export default KPIDetailData;
