import styles from "./css/KPIHeader.module.css";

const KPIHeader = ({ kpiName, kpiFrequency, isKPIDue }) => {
  return (
    <div className={styles.kpiHeader}>
      <b className={styles.kpiName}>{kpiName}</b>
      <div className={isKPIDue ? styles.kpiFrequencyContainer_due : styles.kpiFrequencyContainer}>
        <div className={styles.monthly}>{kpiFrequency}</div>
      </div>
    </div>
  );
};

export default KPIHeader;
