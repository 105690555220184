import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      // Make an API call to the backend to verify the token
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/verify-email/${token}/`, {
        method: 'GET',
      });

      if (response.ok) {
        //redirect to login page and seeting amessage to show that email is verified
        navigate('/login', { state: { message: 'Email verified successfully. You can now login.' } });
      } else {
        // Redirect to error page or show error message
        navigate('/verify-email-failure');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div>
      Verifying your email...
    </div>
  );
};

export default EmailVerification;
