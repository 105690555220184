import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { updateCompany, deleteCompany } from '../api/companyAPI';
import { Dialog, DialogContent, Button, Paper, Typography, Container, TextField, IconButton } from '@mui/material';
import { fetchCompaniesAsync } from '../redux/slices/companiesSlice';
import { fetchDashboardsAsync } from '../redux/slices/dashboardsSlice';

const ModifyCompanyDialog = ({ open, onClose, company, onModify, onDeleted }) => {
    const dispatch = useDispatch();
    const [id, setId] = useState(company ? company.id : '');
    const [name, setName] = useState(company ? company.name : '');
    const [tagline, setTagline] = useState(company ? company.tagline : '');
    const [notes, setNotes] = useState(company ? company.notes : '');
    const [address, setAddress] = useState(company ? company.address : '');
    const [error, setError] = useState('');

    useEffect(() => {
        if (company) {
            setId(company.id);
            setName(company.name);
            setTagline(company.tagline);
            setNotes(company.notes);
            setAddress(company.address);
        }
    }, [company]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updateData = { name, tagline, notes, address};
            const updatedCompany = await updateCompany(id, updateData);
            onModify(updatedCompany);
            dispatch(fetchCompaniesAsync());
        } catch (error) {
            setError(error.message);
        }
    };

    const handleDelete = async () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this company?");
        if (!userConfirmed) return;

        try {
            await deleteCompany(company.id);
            dispatch(fetchCompaniesAsync());
            dispatch(fetchDashboardsAsync(company.id));
            onClose();
            onModify();
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} style={{ padding: '32px', marginTop: 64, marginBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5">
                        Update Company
                        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Typography>
                    <DialogContent>
                        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
                            <TextField fullWidth margin="normal" variant="outlined" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            <TextField fullWidth margin="normal" variant="outlined" label="Tagline" value={tagline} onChange={(e) => setTagline(e.target.value)} />
                            <TextField fullWidth margin="normal" variant="outlined" label="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
                            <TextField fullWidth margin="normal" variant="outlined" label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button type="submit" fullWidth variant="contained" color="primary" style={{ margin: '15px' }}>
                                    Update
                                </Button>
                                <Button variant="contained" fullWidth color="secondary" onClick={onClose} style={{ margin: '15px' }}>
                                    Cancel
                                </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button fullWidth variant="contained" color="primary" onClick={handleDelete} style={{ margin: '15px' }}>
                                    Delete Company
                                </Button>
                            </div>
                            {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
                        </form>
                    </DialogContent>
                </Paper>
            </Container>
        </Dialog>
    );
};

export default ModifyCompanyDialog;