import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchKPIsByHeadingId, addKPI, updateKPI, deleteKPI } from '../../api/kpiAPI';
import { fetchKPIData, addKPIData, updateKPIData, deleteKPIData } from '../../api/kpiDataAPI';

export const fetchKPIsAsync = createAsyncThunk(
  'kpis/fetchKPIsByHeading',
  async (headingId, { rejectWithValue }) => {
    try {
      const kpis = await fetchKPIsByHeadingId(headingId);
      return kpis;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewKPIAsync = createAsyncThunk(
  'kpis/addNewKPI',
  async (kpiData, { rejectWithValue }) => {
    try {
      const response = await addKPI(kpiData);
      const data = await response;
      return data; // Ensure the new KPI is returned
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateKPIAsync = createAsyncThunk(
  'kpis/updateKPI',
  async ({ kpiId, updatedData }, { rejectWithValue }) => {
    try {
      const updatedKPI = await updateKPI(kpiId, updatedData);
      return { kpiId, updatedKPI };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteKPIAsync = createAsyncThunk(
  'kpis/deleteKPI',
  async (kpiId, { rejectWithValue }) => {
    try {
      await deleteKPI(kpiId);
      return kpiId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchKPIDataAsync = createAsyncThunk(
  'kpis/fetchKPIData',
  async (kpi, { rejectWithValue }) => {
    try {
      const kpiData = await fetchKPIData(kpi);
      return { kpi, kpiData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addKPIDataAsync = createAsyncThunk(
  'kpis/addKPIData',
  async (kpiData, { rejectWithValue }) => {
    try {
      const newKPIData = await addKPIData(kpiData);
      return newKPIData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateKPIDataAsync = createAsyncThunk(
  'kpis/updateKPIData',
  async ({ updatedData }, { rejectWithValue }) => {
    try {
      const updatedKPIData = await updateKPIData(updatedData);
      return { updatedKPIData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteKPIDataAsync = createAsyncThunk(
  'kpis/deleteKPIData',
  async ({ kpiDataId, kpiId }, { rejectWithValue }) => {
    try {
      await deleteKPIData(kpiDataId);
      return { kpiDataId, kpiId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  kpis: [],
  isLoading: false,
  error: null,
};

export const kpiSlice = createSlice({
  name: 'kpis',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKPIsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchKPIsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const newKPIs = action.payload.map(kpi => ({ ...kpi, kpiData: [] }));       
        const combinedKPIs = [...state.kpis, ...newKPIs];
        // Eliminate duplicates
        state.kpis = combinedKPIs.filter((kpi, index, self) =>
          index === self.findIndex((t) => t.id === kpi.id)
        );
    })
      .addCase(fetchKPIsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addNewKPIAsync.fulfilled, (state, action) => {
        state.kpis.push({ ...action.payload, kpiData: [] });
      })
      .addCase(addNewKPIAsync.rejected, (state, action) => {
        // Handle the error
      })
      .addCase(updateKPIAsync.fulfilled, (state, action) => {
        const index = state.kpis.findIndex(kpi => kpi.id === action.payload.kpiId);
        if (index !== -1) {
          state.kpis[index] = { ...action.payload.updatedKPI, kpiData: state.kpis[index].kpiData };
        }
      })
      .addCase(deleteKPIAsync.fulfilled, (state, action) => {
        state.kpis = state.kpis.filter(kpi => kpi.id !== action.payload);
      })

      //KPIData reducers

      .addCase(fetchKPIDataAsync.fulfilled, (state, action) => {
        const index = state.kpis.findIndex(kpi => kpi.id === action.payload.kpi);
        if (index !== -1) {
          // state.kpis[index].kpiData = action.payload.kpiData;
          state.kpis[index].kpiData = action.payload.kpiData.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        }
      })
      .addCase(addKPIDataAsync.fulfilled, (state, action) => {
        const index = state.kpis.findIndex(kpi => kpi.id === action.payload.kpi);
        if (index !== -1) {
          state.kpis[index].kpiData.push(action.payload);
          state.kpis[index].kpiData = state.kpis[index].kpiData.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        }
      })
      .addCase(updateKPIDataAsync.fulfilled, (state, action) => {
        const kpiIndex = state.kpis.findIndex(kpi => kpi.id === action.payload.updatedKPIData.kpi);
        if (kpiIndex !== -1) {
          const dataIndex = state.kpis[kpiIndex].kpiData.findIndex(data => data.id === action.payload.updatedKPIData.id);
          if (dataIndex !== -1) {
            state.kpis[kpiIndex].kpiData[dataIndex] = action.payload.updatedKPIData;
          }
        }
      })
      .addCase(deleteKPIDataAsync.fulfilled, (state, action) => {
        const kpiIndex = state.kpis.findIndex(kpi => kpi.id === action.payload.kpiId);
        if (kpiIndex !== -1) {
          state.kpis[kpiIndex].kpiData = state.kpis[kpiIndex].kpiData.filter(data => data.id !== action.payload.kpiDataId);
          state.kpis[kpiIndex].kpiData = state.kpis[kpiIndex].kpiData.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        }
      });
  },
});

export default kpiSlice.reducer;
