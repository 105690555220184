import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Button, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddUserDialog from './AddUserDialog';
import { addUserToCompany, removeUserFromCompany, revokeInvitation } from '../api/companyAPI'; 
import { useSelector } from 'react-redux';
import { fetchCompaniesAsync } from '../redux/slices/companiesSlice';
import { useDispatch } from 'react-redux';

const ManageUsersDialog = ({ open, onClose, users, company, handleAddedUser, handleRemovedUser }) => {
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [invitations, setInvitations] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [error, setError] = useState('');
    const thisUser = useSelector(state => state.user.user && state.user.user[0]);
    const dispatch = useDispatch();

    useEffect(() => {
      if (company && company.invitations) {
          setInvitations(company.invitations);
      }
    }, [company]);

    const handleAddUser = async (email) => {
        setError();
        if (!company || !company.id) {
            console.error('Company or company ID is not defined');
            setError(error.message);
            return;
        }
        
        try {
            const response = await addUserToCompany(company.id, email);
            if (response.invitation) {
                // Update invitations with the returned invitation object
                setInvitations([...invitations, response.invitation]);
            } else if (response.user) {
                handleAddedUser(response.company);
            }
          } catch (error) {
            setError(error.message);
            console.error('Failed to add user:', error);
          }
    };

    const handleRemoveUser = async (user) => {
        setError();
        if (!company || !company.id) {
            console.error('Company or company ID is not defined');
            setError(error.message);
            return;
        }

        try {
            const response = await removeUserFromCompany(company.id, user.id);
            if (response.message) {
                // Update users
                await dispatch(fetchCompaniesAsync());
                handleRemovedUser(response.company);
            } else {
                // Handle the error
            }
        } catch (error) {
            setError(error.message);
            console.error('Failed to remove user:', error);
        }
      };

    const handleRevokeInvitation = async (invitationId) => {
        setError();
        try {
          const response = await revokeInvitation(invitationId);
          if (response.message) {
            // Update invitations
            setInvitations(invitations.filter(inv => inv.id !== invitationId));
          } else {
            // Handle the error
          }
        } catch (error) {
          setError(error.message);
          console.error('Failed to revoke invitation:', error);
        }
      };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                Manage Users
                {(thisUser && company && thisUser.id === company.owner) &&
                  <IconButton onClick={() => {setSelectedCompany(company); setShowAddUserDialog(true)}}>
                    <AddIcon />
                  </IconButton>
                }
            </DialogTitle>
            <DialogContent>
                <List>
                {users && users.map((user, index) => (
                    <ListItem key={index}>
                    <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                    { user.id === company.owner ? (
                        <Typography color="textSecondary">Owner</Typography>
                    ) : (
                      <>
                        { thisUser && thisUser.id === company.owner && <Button onClick={() => handleRemoveUser(user)}>Remove from Company</Button> }
                        <Typography color="textSecondary">Member</Typography>
                      </>
                    )}
                      
                    </ListItem>
                ))}
                
                {invitations && (thisUser && thisUser.id === company.owner) && invitations.map((invitation, index)  => (
                    <ListItem key={index}>
                    <ListItemText primary={invitation.email} secondary="Invited" />
                    <Button onClick={() => handleRevokeInvitation(invitation.id)}>Revoke Invitation</Button>
                    </ListItem>
                ))}
                </List>
                {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
            </DialogContent>
            {(thisUser && company && thisUser.id === company.owner) &&
              <AddUserDialog
                  open={showAddUserDialog}
                  onClose={() => setShowAddUserDialog(false)}
                  onAddUser={handleAddUser}
                  company={selectedCompany}
              />
            }
        </Dialog>
    );
};

export default ManageUsersDialog;