import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showManageCompaniesDialog: false,
  showManageDashboardsDialog: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setShowManageCompaniesDialog: (state, action) => {
      state.showManageCompaniesDialog = action.payload;
    },
    setShowManageDashboardsDialog: (state, action) => {
      state.showManageDashboardsDialog = action.payload;
    },
  },
});

export const { setShowManageCompaniesDialog, setShowManageDashboardsDialog } = uiSlice.actions;

export default uiSlice.reducer;