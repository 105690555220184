const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

export const fetchProjects = async (dashboard_id ) => {

    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/projects/?dashboard_id=${dashboard_id}`, {
        method: 'GET',
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch projects.');
    }
    const data = await response.json();
    // Check if the data is an array - this will be true even for an empty array
    if (!Array.isArray(data)) {
        console.error('Unexpected response format:', data);
        return [];
    }

    return data;
}

export const addProject = async (newProjectData) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/projects/`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newProjectData),
    });
    if (!response.ok) {
        throw new Error('Failed to add project.');
    }
    return await response.json();
}

export const updateProject = async (projectId, updatedData) => {
    try {
        
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/projects/${projectId}/`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Failed to update project (A). Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to update project (B):", error);
        throw error;
    }
}

export const deleteProject = async (projectId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/projects/${projectId}/`, {
            method: 'DELETE',
            headers: getHeaders(),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Failed to delete project. Status: ${response.status}`);
    }
}