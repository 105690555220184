import React from "react";
import styles from './css/CompanyCard.module.css';

export const DashboardCard = ( {handleDashboardSelected, onEdit, onEditUsers, dashboard_obj}) => {
  return (
    <div className={styles.taskCard}>
      <div className={styles.taskframe}>
        <header className={styles.taskheader}>
          <img className={styles.istockphoto} alt="dashboardPhoto" src='/dashboard1.jpg' />
        </header>
        <div className={styles.titleContainer}>
          <div className={styles.cardsTitle}>
            <div className={styles.taskTitleText}>{dashboard_obj.name}</div>
          </div>
          <div className={styles.cardsTitle}>
            <p className={styles.textWrapper}>{dashboard_obj.description}</p>
          </div>
          
        </div>
        <div className={styles.buttonsTopContainer}>
            <button className={styles.taskbutton} onClick={() => handleDashboardSelected(dashboard_obj)} >
                <div className={styles.tasklabel}>Select</div>
            </button>
            <div className={styles.buttonsContainer}>
          <button className={styles.labelWrapper} onClick={() => onEdit(dashboard_obj)} >
            <div className={styles.tasklabel}>Edit</div>
          </button>
          <button className={styles.labelWrapper} onClick={() => onEditUsers(dashboard_obj)} >
            <div className={styles.tasklabel}>Users</div>
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};
