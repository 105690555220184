const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

export const fetchTasks = async (projectId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/tasks/?project_id=${projectId}`, {
        method: 'GET',
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch tasks.');
    }
    return await response.json();
};

export const addTask = async (newTaskData) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/tasks/`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newTaskData),
    });
    if (!response.ok) {
        throw new Error('Failed to add task.');
    }
    return await response.json();
};

export const updateTask = async (taskId, updatedData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/tasks/${taskId}/`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Failed to update task (A). Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to update task (B):", error);
        throw error;
    }
};

export const deleteTask = async (taskId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/tasks/${taskId}/`, {
            method: 'DELETE',
            headers: getHeaders(),
    });

    if (!response.ok) {
            const data = await response.json();
            throw new Error(data.error || 'Failed to delete task.');
    }
};