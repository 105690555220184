import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaskAsync, deleteTaskAsync } from '../redux/slices/tasksSlice';

import styles from "./css/TaskCard.module.css";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { format, parseISO } from 'date-fns';


const ConfirmDialog = ({ open, title, message, onConfirm, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TaskCard = ({
  taskTitleProps, 
  taskDescriptionProps, 
  taskProgress, 
  taskLeader, 
  startDateProp, 
  endDateProp,
  completionDateProp, 
  setTaskStatus, 
  taskId, 
  projectId,
  activeDashboard
}) => {

  const dispatch = useDispatch();
  const [taskTitle, setTaskTitle] = useState(taskTitleProps);
  const [taskDescription, setTaskDescription] = useState(taskDescriptionProps);
  const [statusBgColor, setStatusBgColor] = useState("honeydew");
  const [statusTxtColor, setStatusTxtColor] = useState("green");
  const [statusTxt, setStatusTxt] = useState("On-track");
  const [progressValue, setProgressValue] = useState(taskProgress);
  const [timeLeftValue, setTimeLeftValue] = useState(30);
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
  const [editableTitle, setEditableTitle] = useState(taskTitle);
  const [editableDescription, setEditableDescription] = useState(taskDescription);
  const textDivRefTitle = useRef(null);
  const textDivRefDescription = useRef(null);
  const [numLinesTitle, setNumLinesTitle] = useState(1);
  const [numLinesDescription, setNumLinesDescription] = useState(1);

  const parseAndFormatDate = (dateString) => dateString ? format(parseISO(dateString), 'yyyy-MM-dd') : null;
  const [startDate, setStartDate] = useState(parseAndFormatDate(startDateProp));
  const [endDate, setEndDate] = useState(parseAndFormatDate(endDateProp));
  const [completionDate, setCompletionDate] = useState(parseAndFormatDate(completionDateProp));
  const [timeLeft_Days, setTimeLeft_Days] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [taskLeader_edit, setTaskLeader_edit] = useState(taskLeader);
  const [taskLeader_name, setTaskLeader_name] = useState(`${taskLeader.first_name} ${taskLeader.last_name}`);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

 

    // Inline styles for input fields
    const inputStyle = {
      border: 'none',
      outline: 'none',
      fontFamily: 'inherit', 
      fontSize: 'inherit', 
      fontWeight: 'inherit',
      height: 'inherit',
      outerHeight: 'inherit',
      width: '100%',
      resize: 'none',
      overflow: 'hidden',
      padding: '0px',
      fontStyle: 'italic',
      lineHeight: 'inherit',
      color: 'inherit',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    };

  const handleUpdateTask = ({
    newName,
    newDescription,
    newStartDate,
    newEndDate,
    newCompletionDate,
    newCompletionScore,
    newPriority,
    newLeader
  }) => {
    const updatedTaskData = {
      name: newName ? newName : editableTitle,
      description: newDescription ? newDescription : editableDescription,
      start_date: newStartDate ? newStartDate : startDate,
      due_date: newEndDate ? newEndDate : endDate,
      completion_date: newCompletionDate === null ? null : (newCompletionDate ? newCompletionDate : (completionDate ? completionDate : null)),
      completion_score: newCompletionScore ? newCompletionScore : progressValue,
      priority: newPriority ? newPriority : 1,  //TODO implement priority
      leader_id: newLeader ? newLeader.id : taskLeader_edit.id,
      project: projectId,
      dashboard: activeDashboard.id,
    };
    dispatch(updateTaskAsync({ taskId: taskId, updatedData: updatedTaskData }));
  };

  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);
  const handleDeleteTask = () => {
    dispatch(deleteTaskAsync(taskId));
    handleCloseConfirm();
  };

  const handleChangeStartDate = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setStartDate(formattedDate);
    handleUpdateTask({ newStartDate: formattedDate });
  };

  const handleChangeEndDate = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setEndDate(formattedDate);
    handleUpdateTask({ newEndDate: formattedDate });
  };

  const CustomDatePicker = ({ selectedDate, onChange }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
      <>
        <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
          {selectedDate}
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DatePicker
            selected={new Date(selectedDate)}
            onChange={(date) => {
              onChange(date);
              handleClose();
            }}
            inline
          />
        </Dialog>
      </>
    );
  };


  const handleKeyPress = (event, type) => {
    if(event.key === 'Enter') {
      event.preventDefault();  // Prevent the default action to stop scrolling when pressing enter
      if (type === 'title') {
        setTaskTitle(editableTitle);  // Update the task title
        setIsTitleEditable(false);
        handleUpdateTask( {newName: event.target.value} );
      } else if (type === 'description') {
        setTaskDescription(editableDescription);  // Update the task description
        setIsDescriptionEditable(false);
        handleUpdateTask( {newDescription: event.target.value} );
      }
    }
  };

  const handleTitleChange = (e) => {
    setEditableTitle(e.target.value);
    const textArea = e.target;
    textArea.rows = 1;
    textArea.rows = textArea.scrollHeight / parseInt(getComputedStyle(textArea).lineHeight);
  };

  const handleTitleSave = (e) => {
    handleUpdateTask( {newName: e.target.value} );
  };

  const handleDescriptionChange = (e) => {
    setEditableDescription(e.target.value);
    const textArea = e.target;
    textArea.rows = 1;
    textArea.rows = textArea.scrollHeight / parseInt(getComputedStyle(textArea).lineHeight);
  };

  const handleDescriptionSave = (e) => {
    handleUpdateTask( {newDescription: e.target.value} );
  };

  const handleChange_Progress = (event, newValue) => {
    if (typeof newValue === 'number') {
      setProgressValue(newValue);
    }
  };

  const handleChange_Progress_Save = (event, newValue) => {
    if (typeof newValue === 'number') {
      setProgressValue(newValue);
      handleUpdateTask( {newCompletionScore: newValue} );
    }
  };

  const handleUserMenuOpen = (event, user) => setUserMenuAnchorEl(event.currentTarget);

  const handleUserMenuClose = () => setUserMenuAnchorEl(null);

  const handleMarkCompleteProgress = () => {
    if (completionDate) {
      setProgressValue(0);
      setCompletionDate(null);
      handleUpdateTask( {newCompletionDate: null, newCompletionScore: 0} );
    } else {
      setProgressValue(100);
      const formattedDate = format(new Date(), 'yyyy-MM-dd');
      setCompletionDate(formattedDate);
      handleUpdateTask( {newCompletionDate: formattedDate, newCompletionScore: 100} );
    }
    handleMenuClose();
  };

  const handleUserSelect = (user) => {
    setTaskLeader_edit(user);
    setTaskLeader_name(`${user.first_name} ${user.last_name}`);
    handleUpdateTask( {newLeader: user} );
    handleUserMenuClose();
  };

  useEffect(() => {
    if (textDivRefTitle.current) {
      const lineHeight = parseInt(window.getComputedStyle(textDivRefTitle.current).lineHeight, 10);
      const clientHeight = textDivRefTitle.current.clientHeight;
      const lines = Math.ceil(clientHeight / lineHeight);
      setNumLinesTitle(lines);
    }
    if (textDivRefDescription.current) {
      const lineHeight = parseInt(window.getComputedStyle(textDivRefDescription.current).lineHeight, 10);
      const clientHeight = textDivRefDescription.current.clientHeight;
      const lines = Math.ceil(clientHeight / lineHeight);
      setNumLinesDescription(lines);
    }

  }, [editableDescription,editableTitle]);


  useEffect(() => {
    const updateStatus = (status) => {
      setStatusTxt(status);
      setTaskStatus(prevStatus => ({ ...prevStatus, [taskId]: status }));
    };
  
    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();
  
    // Calculate total time span and time elapsed
    const totalTime = end.getTime() - start.getTime();
    const elapsedTime = now.getTime() - start.getTime();
    let timeLeft_Days_calc = Math.ceil((end.getTime() - now.getTime()) / (1000 * 3600 * 24));
    setTimeLeft_Days(timeLeft_Days_calc);
    
    // Calculate the percentage of time elapsed
    let timeLeftValue = ((elapsedTime / totalTime) * 100).toFixed(0);
    timeLeftValue = parseInt(timeLeftValue, 10);
    timeLeftValue = Math.max(0, Math.min(100, timeLeftValue));
    
    // Update the state
    setTimeLeftValue(timeLeftValue);
  
    if (completionDate !== null) {
      // Set status to "Completed"
      setStatusBgColor("#D4EDDA");  // A light green color
      setStatusTxtColor("#155724");  // A dark green color
      setStatusTxt("Completed");
      updateStatus("Completed");
    } else if (timeLeftValue > progressValue) {
      // Update colors when the condition is met
      setStatusBgColor("#ff000094"); 
      setStatusTxtColor("black");  
      setStatusTxt("Behind Schedule");
      updateStatus("Behind Schedule");  
    } else {
      // Reset to default colors
      setStatusBgColor("honeydew");
      setStatusTxtColor("green");
      setStatusTxt("On-Track");
      updateStatus("On-Track");  
    }

  }, [startDate, endDate, progressValue, setTaskStatus, taskId, completionDate]);

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name) {
        return {
      sx: {
        bgcolor: stringToColor(name),
        width: 28,
        height: 28,
        fontSize: 14,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  

  return (
    <div className={styles.taskcard}>
      <div className={styles.headerParent}>
        <div className={styles.header}>
          <div className={styles.owner} onClick={(e) => handleUserMenuOpen(e, taskLeader)}>
            <Avatar {...stringAvatar(taskLeader_name)}/>
          </div>
          <Menu
            anchorEl={userMenuAnchorEl}
            keepMounted
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
          >
            {activeDashboard.user_objects.map((user, index) => (
              <MenuItem key={index} onClick={() => handleUserSelect(user)}>
                <ListItemAvatar>
                  <Avatar {...stringAvatar(`${user.first_name} ${user.last_name}`)}/>
                </ListItemAvatar>
                {`${user.first_name} ${user.last_name}`}
              </MenuItem>
            ))}
          </Menu>
          <div className={styles.badge} style={{backgroundColor: statusBgColor, color: statusTxtColor}}>
            <div className={styles.status}>{statusTxt}</div>
          </div>
          <div className={styles.button}>
            <MoreHoriz onClick={handleMenuOpen} />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem key={1} onClick={handleMarkCompleteProgress} style={{fontSize:'12px',}}>
                <CheckCircleOutlineIcon style={{marginRight:'12px', color:'rgba(0, 0, 0, 0.6)', fontSize:'18px',}}/>  
                {completionDate ? "Mark as in progress" : "Mark as complete"}
              </MenuItem>
              <MenuItem 
                key={2} 
                onClick={() => {
                  handleOpenConfirm();
                  handleMenuClose();
                }} 
                style={{fontSize:'12px',}}
              >
                <DeleteForeverIcon style={{marginRight:'12px', color:'rgba(0, 0, 0, 0.6)', fontSize:'18px',}}/>  
                Delete task
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.cardsTitle}>
            {isTitleEditable ? (
              <textarea
                ref={textDivRefTitle}
                autoFocus  
                rows={numLinesTitle}
                value={editableTitle}
                onChange={handleTitleChange}
                onKeyDown={(e) => handleKeyPress(e, 'title')}
                onBlur={(e) => {
                  setTaskTitle(editableTitle); // Save the new title
                  setIsTitleEditable(false); // Exit edit mode for the title
                  handleTitleSave(e);
                }}
                style={{
                  ...inputStyle,
                  fontSize: '12px',  // Add the same font size as .taskTitleText
                  lineHeight: '15px',  // Add the same line height as .taskTitleText
                }}
              />
            ) : (
              <div
                onClick={() => setIsTitleEditable(true)}
                ref={textDivRefTitle}
                className={styles.taskTitleText}
              >
                {taskTitle}
              </div>
            )}
            {isDescriptionEditable ? (
              <textarea
                ref={textDivRefDescription}
                autoFocus  
                rows={numLinesDescription}
                value={editableDescription}
                onChange={handleDescriptionChange}
                onKeyDown={(e) => handleKeyPress(e, 'description')}
                onBlur={(e) => {
                  setTaskDescription(editableDescription); // Save the new title
                  setIsDescriptionEditable(false); // Exit edit mode for the title
                  handleDescriptionSave(e);
                }}
                style={{
                  ...inputStyle,
                  fontSize: '10px',  // Add the same font size as .asATeam
                  lineHeight: '100%',  // Add the same line height as .asATeam
                }}
              />
            ) : (
              <div
                onClick={() => setIsDescriptionEditable(true)}
                ref={textDivRefDescription}
                className={styles.asATeam}
              >
                {taskDescription}
              </div>
            )}
          </div>
        </div>
        <div className={styles.timeleft}>
          <div className={styles.progresstext}>
            <div className={styles.dates}>
              <div className={styles.startdate}>
                <img className={styles.flagIcon}  alt="" src="/flag.png" />
                <CustomDatePicker selectedDate={startDate} onChange={handleChangeStartDate} />
              </div>
              {completionDateProp === null && (
                timeLeft_Days > 0 ? (
                  <div className={styles.timeLeft}>{timeLeft_Days} {timeLeft_Days > 1 ? ('Days') : ('Day')} Left</div>
                ) : (
                  <div className={styles.pastDue} >{-timeLeft_Days} {-timeLeft_Days > 1 ? ('Days') : ('Day')} Past Due</div>
                )
              )}
              <div className={styles.enddate}>
                <CustomDatePicker selectedDate={endDate} onChange={handleChangeEndDate} />
                <img className={styles.flagIcon} alt="" src="/flag1.png" />
              </div>
            </div>
          </div>
          <LinearProgress variant="determinate" value={timeLeftValue} style={{ width: '100%' }}/>
        </div>
        <div className={styles.progress}>
          <div className={styles.progresstext}>
            <div className={styles.div2}>0</div>
            <div className={styles.progress1}>Progress [%]</div>
            <div className={styles.div2}>100</div>
          </div>
          <Slider
            size="small"
            value={progressValue}
            style={{ padding: '5px 0px' }}
            onChange={handleChange_Progress}
            onChangeCommitted={handleChange_Progress_Save}
            disabled={completionDate !== null}
            sx={{
              '& .MuiSlider-thumb': {
                width: 10,
                height: 10,
                backgroundColor: '#fff',
                '&:before': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none',
                },
              },
            }}
          />
        </div>
      </div>
      <ConfirmDialog
        open={confirmOpen}
        title="Confirm Delete"
        message="Are you sure you want to delete this task?"
        onConfirm={handleDeleteTask}
        onCancel={handleCloseConfirm}
      />
    </div>
  );
};

export default TaskCard;