import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'; // Import the pencil/edit icon
import styles from "./css/ObjectiveCard.module.css";

const ObjectiveCard = ({ objectiveName, objectiveDescription, onEdit  }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className={styles.objectiveCard} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <div className={styles.editOverlay} onClick={onEdit}>
          <EditIcon className={styles.editIcon} />
        </div>
      )}
      <div className={styles.objective1TextContainer}>
        <b className={styles.objective1Name}>{objectiveName}</b>
        <div className={styles.objective1Longer}>{objectiveDescription}</div>
      </div>
    </div>
  );
};

export default ObjectiveCard;