// Fetch all companies
export const fetchCompanies = async () => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to fetch companies');
    }
    return await response.json();
  };
  
  // Fetch specific company details
  export const fetchCompanyData = async (companyId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/${companyId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to fetch company data');
    }
    return await response.json();
  };
  
  // Create a new company
  export const createCompany = async (companyData) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(companyData),
    });
  
    if (!response.ok) {
      throw new Error('Failed to create company');
    }
  
    return response.json();
  };
  
  // Update an existing company
  export const updateCompany = async (companyId, updateData) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/${companyId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updateData),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to update company');
    }
  
    return await response.json();
  };
  
  // Delete a company
  export const deleteCompany = async (companyId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/${companyId}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to delete company.');
    }
  };


export const addUserToCompany = async (companyId, email) => {
    const token = localStorage.getItem('access_token');
  try {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/${companyId}/add_user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to invite user');
    }

    return await response.json();
  } catch (error) {
    console.error('Error inviting user:', error);
    throw error;
  }
};

export const removeUserFromCompany = async (companyId, userId) => {
    const token = localStorage.getItem('access_token');
  try {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/companies/${companyId}/remove_user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ user_id: userId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to remove user');
    }

    return await response.json(); // Return the updated invitation
  } catch (error) {
    console.error('Error removing user:', error);
      throw error;
  }
}

export const revokeInvitation = async (invitationId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/invitations/${invitationId}/revoke/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        },
    });
    return response.json();
};  