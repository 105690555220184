import React, { useState } from 'react';
import { useNavigate, Link  } from 'react-router-dom';
import { Button, TextField, Grid, Paper, Typography, Container } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const SignupComponent = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password1: '',
    password2: '',
    firstname: '',
    lastname: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        // Handle success (e.g., redirect to dashboard)
        // setMessage(data.message);
        setMessage("Signup successful! Please check your email to verify your account.");
        setDialogOpen(true); // Open the dialog
      } else {
        // Handle error
        setMessage(data.error || 'Signup failed');
      }
    } catch (error) {
      // Handle network or other errors
      setMessage('An error occurred. Please try again.');
    }
  };

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate('/login'); // Navigate to the login page
  };

  return (
    <Grid container justify="center">
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: 32, marginTop: 64 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Sign Up
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField fullWidth label="First Name" name="firstname" onChange={handleChange} variant="outlined" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Last Name" name="lastname" onChange={handleChange} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Email" name="email" type="email" onChange={handleChange} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Password" name="password1" type="password" onChange={handleChange} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Confirm Password" name="password2" type="password" onChange={handleChange} variant="outlined" />
              </Grid>
              
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </form>
          {message && (
            <Typography color="error" align="center" style={{ marginTop: 16 }}>
              {message}
            </Typography>
          )}
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <MuiLink component={Link} to="/login" variant="body2">
              {"Already have an account? Sign In"}
            </MuiLink>
          </div>
        </Paper>
      </Container>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Signup Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
    
  );
};

export default SignupComponent;