import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGoals, addGoal, editGoal, deleteGoal } from '../../api/goalsAPI'; // Import API functions

// Thunk for fetching goals
export const fetchGoalsAsync = createAsyncThunk(
  'goals/fetchGoals',
  async (dashboardId) => {
    const goals = await fetchGoals(dashboardId);
    return goals;
  }
);

// Thunk for adding a goal
export const addGoalAsync = createAsyncThunk(
  'goals/addGoal',
  async (goalData) => {
    const newGoal = await addGoal(goalData);
    return newGoal;
  }
);

// Thunk for editing a goal
export const editGoalAsync = createAsyncThunk(
  'goals/editGoal',
  async ({ id, data }) => {
    const updatedGoal = await editGoal(id, data);
    return { id, updatedGoal };
  }
);

// Thunk for deleting a goal
export const deleteGoalAsync = createAsyncThunk(
  'goals/deleteGoal',
  async (goalId, { rejectWithValue }) => {
    try {
      await deleteGoal(goalId);
      return goalId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  goals: [],
  isLoading: false,
  error: null,
};

const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoalsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGoalsAsync.fulfilled, (state, action) => {
        state.goals = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchGoalsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addGoalAsync.fulfilled, (state, action) => {
        state.goals.push(action.payload);
      })
      .addCase(editGoalAsync.fulfilled, (state, action) => {
        const { id, updatedGoal } = action.payload;
        const index = state.goals.findIndex((goal) => goal.id === id);
        if (index !== -1) {
          state.goals[index] = updatedGoal;
        }
      })
      .addCase(deleteGoalAsync.fulfilled, (state, action) => {
        const id = action.payload;
        state.goals = state.goals.filter((goal) => goal.id !== id);
      });
  },
});

export default goalsSlice.reducer;