import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProjects, addProject, updateProject, deleteProject } from '../../api/projectsAPI';

export const fetchProjectsAsync = createAsyncThunk(
    'projects/fetchProjects',
    async (dashboard_id , { rejectWithValue }) => {
        try {
            const response = await fetchProjects(dashboard_id);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addProjectAsync = createAsyncThunk(
    'projects/addProject',
    async (newProjectData, { rejectWithValue }) => {
        try {
            const response = await addProject(newProjectData);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateProjectAsync = createAsyncThunk(
    'projects/updateProject',
    async ({ projectId, updatedData }) => {
        const updatedProject = await updateProject(projectId, updatedData);
        return { projectId, updatedProject };
    }
);

export const deleteProjectAsync = createAsyncThunk(
    'projects/deleteProject',
    async (projectId, { rejectWithValue }) => {
        try {
            await deleteProject(projectId);
            return projectId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    projects: [],
    isLoading: false,
    error: null,
};

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsAsync.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.projects = action.payload || [];
            })
            .addCase(fetchProjectsAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addProjectAsync.fulfilled, (state, action) => {
                state.projects.push(action.payload);
            })
            .addCase(addProjectAsync.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateProjectAsync.fulfilled, (state, action) => {
                const { projectId, updatedProject } = action.payload;
                const existingProject = state.projects.find(project => project.id === projectId);
                if (existingProject) {
                    Object.assign(existingProject, updatedProject);
                }
            })
            .addCase(deleteProjectAsync.fulfilled, (state, action) => {
                state.projects = state.projects.filter(project => project.id !== action.payload);
            })
            .addCase(deleteProjectAsync.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export default projectsSlice.reducer;