import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchKPIHeadings, addKPIHeading, updateKPIHeading, deleteKPIHeading } from '../../api/kpiHeadingsAPI';

export const fetchKPIHeadingsAsync = createAsyncThunk(
  'kpiHeadings/fetchKPIHeadings',
  async (businessegment_id , { rejectWithValue }) => {
    try {
      const response = await fetchKPIHeadings(businessegment_id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addKPIHeadingAsync = createAsyncThunk(
  'kpiHeadings/addKPIHeading',
  async (newKPIHeading, { rejectWithValue }) => {
    try {
      const response = await addKPIHeading(newKPIHeading);
      const data = await response;
      return data; // Ensure the new KPI Heading is returned
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateKPIHeadingAsync = createAsyncThunk(
  'kpiHeadings/updateKPIHeading',
  async ({ kpiHeadingId, updatedData }) => {
    const updatedHeading = await updateKPIHeading(kpiHeadingId, updatedData);
    return { kpiHeadingId, updatedHeading };
  }
);

export const deleteKPIHeadingAsync = createAsyncThunk(
  'kpiHeadings/deleteKPIHeading',
  async (kpiHeadingId, { rejectWithValue }) => {
    try {
      await deleteKPIHeading(kpiHeadingId);
      return kpiHeadingId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  kpiHeadings: [],
  isLoading: false,
  error: null,
};

const kpiHeadingsSlice = createSlice({
  name: 'kpiHeadings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKPIHeadingsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchKPIHeadingsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const newkpiHeading = action.payload;
        const combinedKpiHeadings = [...state.kpiHeadings, ...newkpiHeading];
        // Eliminate duplicates
        state.kpiHeadings = combinedKpiHeadings.filter((kpiHeading, index, self) =>
        index === self.findIndex((t) => t.id === kpiHeading.id)
        );
      })
      .addCase(fetchKPIHeadingsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addKPIHeadingAsync.fulfilled, (state, action) => {
        const newkpiHeading = action.payload || [];
        const combinedKpiHeadings = [...state.kpiHeadings, newkpiHeading];
        state.kpiHeadings = combinedKpiHeadings.filter((kpiHeading, index, self) =>
        index === self.findIndex((t) => t.id === kpiHeading.id)
        );
      })
      .addCase(addKPIHeadingAsync.rejected, (state, action) => {
        // Handle the error
      })
      .addCase(updateKPIHeadingAsync.fulfilled, (state, action) => {
        const { kpiHeadingId, updatedHeading } = action.payload;
        const existingHeading = state.kpiHeadings.find(kpiHeading => kpiHeading.id === kpiHeadingId);
        if (existingHeading) {
          Object.assign(existingHeading, updatedHeading);
        }
      })
      .addCase(deleteKPIHeadingAsync.fulfilled, (state, action) => {
        state.kpiHeadings = state.kpiHeadings.filter(kpiHeading => kpiHeading.id !== action.payload);
      });
  },
});

export default kpiHeadingsSlice.reducer;
