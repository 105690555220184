import styles from "./css/DynamicTimeline.module.css";
const DynamicTimeline = () => {
  return (
    <div className={styles.dynamicTimeline}>
      <div className={styles.ellipseParent}>
        <div className={styles.frameItem} />
      </div>
    </div>
  );
};

export default DynamicTimeline;
