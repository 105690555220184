import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateDashboardAsync } from '../redux/slices/dashboardsSlice';

const ManageDashboardUsersDialog = ({ open, onClose, company, dashboard: initialDashboard }) => {
    const [error, setError] = useState('');
    const [dashboard, setDashboard] = useState(initialDashboard);
    const dispatch = useDispatch();
    const thisUser = useSelector(state => state.user.user && state.user.user[0]);

    useEffect(() => {
        setDashboard(initialDashboard);
    }, [initialDashboard]);
    
    const handleAddUser = async (user) => {
      const modifiedData = {
        ...dashboard,
        users: dashboard.user_objects.map(user => user.id).concat(user.id),
      };

      dispatch(updateDashboardAsync(modifiedData))
        .then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
            setDashboard(result.payload);
          }
        })
        .catch(() => setError('Failed to modify dashboard. Please try again.'));
    };

    const handleRemoveUser = async (user) => {
      const modifiedData = {
        ...dashboard,
        users: dashboard.user_objects.map(u => u.id).filter(id => id !== user.id),
      };

      dispatch(updateDashboardAsync(modifiedData))
        .then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
            setDashboard(result.payload);
          }
        })
        .catch(() => setError('Failed to modify dashboard. Please try again.'));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                Manage Users
            </DialogTitle>
            <DialogContent>
            <List>
              {(company && company.users) && company.users.map((user, index) => {
                return (
                    <ListItem key={index}>
                        <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                        { (dashboard && dashboard.user_objects) && dashboard.user_objects.some(dashboardUser => dashboardUser.id === user.id) ? (
                            <>
                                {dashboard && dashboard.owner && dashboard.owner.id === user.id ? (
                                  <Typography color="textSecondary">Owner</Typography>
                                ) : (
                                  <>
                                    {
                                      thisUser && thisUser.id &&
                                      dashboard && dashboard.owner &&
                                      user &&
                                      thisUser.id === dashboard.owner.id && 
                                      <Button onClick={() => { handleRemoveUser(user) }}>Remove from Dashboard</Button>
                                    }
                                  </>
                                )}
                            </>
                        ) : (
                          <>
                            {
                              (
                                (dashboard && dashboard.owner && thisUser && thisUser.id === dashboard.owner.id) ||
                                (company && company.owner && thisUser && thisUser.id === company.owner)
                              ) && (
                                <Button onClick={() => { handleAddUser(user) }}>Add to Dashboard</Button>
                              )
                            }
                          </>
                        )}
                    </ListItem>
                );
            })}
            </List>
                {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
            </DialogContent>
        </Dialog>
    );
};

export default ManageDashboardUsersDialog;