export const fetchBusinessSegments = async (dashboardId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/business_segments/?dashboard_id=${dashboardId}`, {
        method: 'GET',
        headers: {
        'Authorization': `Bearer ${token}`,
        },
    });
    const data = await response.json();
    if (response.ok) {
        return data;
    } else {
        throw new Error(data.error || 'Failed to fetch business segments.');
    }
};

export const addBusinessSegment = async (segmentData) => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/business_segments/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(segmentData),
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Failed to save business segment:", error);
        throw error;
      }
};
  
export const updateBusinessSegment = async (segmentId, updatedData) => {
  try {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/business_segments/${segmentId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to update business segment:", error);
    throw error;
  }
};

// Delete a business segment
export const deleteBusinessSegment = async (segmentId) => {
  const token = localStorage.getItem('access_token');
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/business_segments/${segmentId}/`, {
      method: 'DELETE',
      headers: {
          'Authorization': `Bearer ${token}`,
      },
  });

  if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to delete business segment.');
  }
};

