import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CompanyMainLogo from "./CompanyMainLogo";
import CompanyID from "./CompanyID";
import Account from "./Account";
import styles from "./css/HeaderContent.module.css";
import ModifyUserDialog from './ModifyUserDialog';
import { fetchCurrentUserAsync } from '../redux/slices/userSlice';


const HeaderContent = ({
  companyLogo = "206x44.48x418886218",
  companyName = "Select a Company",
  companyTagline = "...",
  userAvatar = "45x45x-1939033964",
  selectDifferentCompany,
  thisUser
}) => {

  const dispatch = useDispatch();
  const [showModifyUserDialog, setShowModifyUserDialog] = useState(false);

  const editUser = () => {
    setShowModifyUserDialog(true);
  }

  const onUserModified = (udpatedUser) => {
    setShowModifyUserDialog(false);
    dispatch(fetchCurrentUserAsync());
  }


  return (
    <div className={styles.headerContent}>
      <CompanyMainLogo companyLogo={companyLogo} />
      <CompanyID companyName={companyName} companyTagline={companyTagline} />
      <Account
        userAvatar={userAvatar}
        userFullName={thisUser ? `${thisUser.first_name} ${thisUser.last_name}` : "Loading..."}
        userEmail={thisUser ? thisUser.email : "Loading..."}
        selectDifferentCompany={selectDifferentCompany}
        onEditUser={editUser}
      />
      <ModifyUserDialog
            open={showModifyUserDialog}
            onClose={() => setShowModifyUserDialog(false)}
            user={thisUser}
            onUserModified={onUserModified}
        />
    </div>
  );
};

export default HeaderContent;