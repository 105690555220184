import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createDashboard, updateDashboard, deleteDashboard, fetchDashboards } from '../../api/dashboardAPI';

// Async thunk for fetching dashboards
export const fetchDashboardsAsync = createAsyncThunk(
  'dashboards/fetchDashboards',
  async (companyId) => {
    const dashboards = await fetchDashboards(companyId);
    return dashboards;
  }
);

// Async thunk for creating a dashboard
export const createDashboardAsync = createAsyncThunk(
  'dashboards/createDashboard',
  async (dashboardData) => {
    const newDashboard = await createDashboard(dashboardData);
    return newDashboard;
  }
);

// Async thunk for updating a dashboard
export const updateDashboardAsync = createAsyncThunk(
  'dashboards/updateDashboard',
  async (modifiedData) => {
    const updatedDashboard = await updateDashboard(modifiedData);
    return updatedDashboard;
  }
);

// Async thunk for deleting a dashboard
export const deleteDashboardAsync = createAsyncThunk(
  'dashboards/deleteDashboard',
  async (dashboardId, { rejectWithValue }) => {
    try {
      await deleteDashboard(dashboardId);
      return dashboardId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  dashboards: [],
  isLoading: false,
  error: null,
};

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardsAsync.fulfilled, (state, action) => {
        state.dashboards = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchDashboardsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createDashboardAsync.fulfilled, (state, action) => {
        state.dashboards.push(action.payload);
      })
      .addCase(updateDashboardAsync.fulfilled, (state, action) => {
        const index = state.dashboards.findIndex(d => d.id === action.payload.id);
        if (index !== -1) {
          state.dashboards[index] = action.payload;
        }
      })
      .addCase(deleteDashboardAsync.fulfilled, (state, action) => {
        state.dashboards = state.dashboards.filter(d => d.id !== action.payload);
      });
  },
});

export const { setActiveDashboard } = dashboardsSlice.actions;
export default dashboardsSlice.reducer;



