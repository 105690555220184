import React from 'react';
import styles from "./css/Account.module.css";
import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logoutUserAsync } from '../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';

const Account = ({
  userAvatar = "/avatardefault@2x.png",
  userFullName,
  userEmail,
  selectDifferentCompany,
  onEditUser,
}) => {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logoutUserAsync());
      dispatch({ type: 'RESET_STATE' });
      dispatch({ type: 'PURGE_STATE' });
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  return (
    <>
      <div>
        <div className={styles.account} onClick={handleClick}>
          <div className={styles.userSection}>
            <img className={styles.avatarDefaultIcon} alt="" src={userAvatar} />
            <div className={styles.userData}>
              <div className={styles.userName}>
                <div className={styles.austinRobertson}>{userFullName}</div>
              </div>
              <div className={styles.userRole}>
                <div className={styles.marketingAdministra}>{userEmail}</div>
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={onEditUser}>Edit Profile</MenuItem>
          <MenuItem onClick={selectDifferentCompany}>Select Dashboard</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default Account;
