import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, TextField, Button, Paper, Typography, Container } from '@mui/material';
import { updateDashboardAsync, deleteDashboardAsync } from '../redux/slices/dashboardsSlice';
import { fetchDashboardsAsync } from '../redux/slices/dashboardsSlice';

const ModifyDashboardDialog = ({ open, onClose, dashboard, onDashboardModified }) => {
  const dispatch = useDispatch();
  const [dashboardName, setDashboardName] = useState(dashboard ? dashboard.name : '');
  const [dashboardDescription, setDashboardDescription] = useState(dashboard ? dashboard.description : '');
  const [dashboardNotes, setDashboardNotes] = useState(dashboard ? dashboard.notes : '');
  const [error, setError] = useState('');
  const activeCompany = useSelector(state => state.activeCompany.current);

  useEffect(() => {
    if (dashboard) {
      dispatch(fetchDashboardsAsync(dashboard.company));
      setDashboardName(dashboard.name);
      setDashboardDescription(dashboard.description);
      setDashboardNotes(dashboard.notes);
    }
  }, [dispatch, dashboard]);

  

  const handleModify = async (event) => {
    event.preventDefault();
    const modifiedData = {
      id: dashboard.id,
      name: dashboardName,
      description: dashboardDescription,
      notes: dashboardNotes,
      company: activeCompany.id,
      users: dashboard.users,
    };

    dispatch(updateDashboardAsync(modifiedData))
      .then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          onDashboardModified(modifiedData);
          onClose();
        }
      })
      .catch(() => setError('Failed to modify dashboard. Please try again.'));
  };

  const handleDelete = async () => {
    dispatch(deleteDashboardAsync(dashboard.id))
      .then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          onClose();
        }
      })
      .catch(() => setError('Failed to delete dashboard. Please try again.'));
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{ padding: '32px', marginTop: 64, marginBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Modify Dashboard
          </Typography>
          <DialogContent>
            <form onSubmit={handleModify} style={{ width: '100%', marginTop: '8px' }}>
              <TextField fullWidth margin="normal" variant="outlined" label="Name" value={dashboardName} onChange={(e) => setDashboardName(e.target.value)} required />
              <TextField fullWidth margin="normal" variant="outlined" label="Description" value={dashboardDescription} onChange={(e) => setDashboardDescription(e.target.value)} required />
              <TextField fullWidth margin="normal" variant="outlined" label="Notes" value={dashboardNotes} onChange={(e) => setDashboardNotes(e.target.value)} required />
              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
                Modify
              </Button>
              <Button fullWidth variant="contained" color="secondary" style={{ marginTop: '12px' }} onClick={handleDelete}>
                Delete
              </Button>
              {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
            </form>
          </DialogContent>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default ModifyDashboardDialog;