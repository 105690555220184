import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, TextField, Button, Paper, Typography, Container } from '@mui/material';
import { updateUserAsync } from '../redux/slices/userSlice';


const ModifyUserDialog = ({ open, onClose, user, onUserModified }) => {
  const dispatch = useDispatch();
  const [userFirstName, setUserFirstName] = useState(user ? user.first_name : '');
  const [userLastName, setUserLastName] = useState(user ? user.last_name : '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
        setUserFirstName(user.first_name);
        setUserLastName(user.last_name);
    }
  }, [dispatch, user]);

  

  const handleModify = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const modifiedData = {
      id: user.id,
      first_name: userFirstName,
      last_name: userLastName,
    };

    if (newPassword) {
      modifiedData.new_password = newPassword;
    }

    dispatch(updateUserAsync(modifiedData))
      .then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
            onUserModified(modifiedData);
            setConfirmPassword('');
            setNewPassword('');
          onClose();
        }
      })
      .catch(() => setError('Failed to modify user. Please try again.'));
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{ padding: '32px', marginTop: 64, marginBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Modify Profile Data
          </Typography>
          <DialogContent>
            <form onSubmit={handleModify} style={{ width: '100%', marginTop: '8px' }}>
              <TextField fullWidth margin="normal" variant="outlined" label="Name" value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} required />
              <TextField fullWidth margin="normal" variant="outlined" label="Description" value={userLastName} onChange={(e) => setUserLastName(e.target.value)} required />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Confirm New Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
                Save
              </Button>
              <Button fullWidth variant="contained" color="secondary" style={{ marginTop: '12px' }} onClick={onClose}>
                Cancel
              </Button>
              {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
            </form>
          </DialogContent>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default ModifyUserDialog;