import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Box from '@mui/material/Box';
import HeaderContent from "../components/HeaderContent";
import BannerContainer from "../components/BannerContainer";
import DynamicTimeline from "../components/DynamicTimeline";
import BusinessSegmentContainer from "../components/BusinessSegmentContainer";
import ProjectTaskContainer from "../components/ProjectTaskContainer";
import styles from "../components/css/Dashboard.module.css";
import { fetchCurrentUserAsync } from '../redux/slices/userSlice';
import MainMenuComponent from '../components/MainMenuComponent';
import { fetchCompaniesAsync } from '../redux/slices/companiesSlice';
import { fetchDashboardsAsync } from '../redux/slices/dashboardsSlice';
import { setActiveCompany } from '../redux/slices/activeCompanySlice';
import { setActiveDashboard } from '../redux/slices/activeDashboardSlice';

const Dashboard = () => {
  const headerRef = useRef(null);
  const summaryRef = useRef(null);
  const timelineRef = useRef(null);
  const scrollableRef = useRef(null);
  const [availableHeight, setAvailableHeight] = useState('100vh');

  const dispatch = useDispatch();

  const activeCompany = useSelector(state => state.activeCompany.current); 
  const activeDashboard = useSelector(state => state.activeDashboard.current);
  const companies = useSelector(state => state.companies.companies);
  const dashboards = useSelector(state => state.dashboards.dashboards);
  const thisUser = useSelector(state => state.user.user && state.user.user[0]);

  const selectDifferentCompany = () => { 
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'PURGE_STATE' });
    dispatch(fetchCompaniesAsync());
    if (scrollableRef.current) {
        scrollableRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  }

  const onCompanyCreated = () => {
    dispatch(fetchCompaniesAsync());
  }

  const handleCompanySelected = (company) => {
    dispatch(setActiveCompany(company));
    dispatch(fetchDashboardsAsync(company.id));
    if (scrollableRef.current) {
        scrollableRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  }
  
  const handleDashboardSelected = (dashboard) => {
    dispatch(setActiveDashboard(dashboard));
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const timelineBottom = timelineRef.current ? timelineRef.current.getBoundingClientRect().bottom : 0;
      const viewportHeight = window.innerHeight;
      const availableHeight = viewportHeight - timelineBottom;
 
      setAvailableHeight(availableHeight);
    });

    observer.observe(document, { childList: true, subtree: true });

    
    setAvailableHeight(availableHeight);
    dispatch(fetchCurrentUserAsync());
    dispatch(fetchCompaniesAsync());

    return () => observer.disconnect();
  }, [dispatch, availableHeight]);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.snappyScrollArea}>
        <div className={styles.body}>
          <div ref={headerRef} className={styles.header}>
            <HeaderContent
              companyLogo={"/color-logo-with-background-1@2x.png"}
              companyName={activeCompany ? activeCompany.name : "Loading..."}
              companyTagline={activeCompany ? activeCompany.tagline : "Loading..."}
              userAvatar="/avatardefault@2x.png"
              selectDifferentCompany={selectDifferentCompany}
              thisUser={thisUser}
            />
            <div className={styles.headerseparator} />
          </div>
          <div ref={summaryRef} className={styles.companySummary}>
            {activeCompany && activeDashboard ? (
              <BannerContainer
                bannerContainerBackgroundImage="url(/banner-container@3x.png)"
                activeDashboard={activeDashboard}
              />
            ) : (
              <div />
            )}
          </div>
          {activeCompany && activeDashboard ? (
            <div ref={timelineRef} className={styles.timelineContainert}>
              <DynamicTimeline />
            </div>
          ) : (
            <div />
          )}
          
          <div className={styles.dashboardContainer}>
            <div ref={scrollableRef} className={styles.scrollContainer}>
              <div className={styles.scrollItem}>
                {activeCompany && activeDashboard ? (
                  <BusinessSegmentContainer
                    activeDashboard={activeDashboard}
                  />
                ) : (
                  <MainMenuComponent 
                    companies={companies}
                    handleCompanySelected={handleCompanySelected}
                    onCompanyCreated={onCompanyCreated}
                  />
                )}
              </div>
              <div className={styles.scrollItem}>
                {activeCompany && activeDashboard ? (
                  <ProjectTaskContainer 
                    activeDashboard={activeDashboard}
                  />
                ) : (
                  <MainMenuComponent 
                    activeCompany={activeCompany}
                    dashboards={dashboards}
                    handleDashboardSelected={handleDashboardSelected}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;