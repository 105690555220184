import React, { useEffect } from 'react';
import styles from "./css/TaskContainer.module.css";
import TaskCard from "./TaskCard";
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import { addTaskAsync, fetchTasksAsync } from '../redux/slices/tasksSlice';
import { format } from 'date-fns';

// New component for the "Add New Task" card
const AddNewTaskCard = ({ activeDashboard, projectId }) => {

  const dispatch = useDispatch();


  const handleAddNewTask = (activeDashboard, projectId) => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 7);

    const updatedTaskData = {
      name: 'New Task',
      description: 'New Task Description',
      start_date: format(new Date(), 'yyyy-MM-dd'),
      due_date: format(futureDate, 'yyyy-MM-dd'),
      completion_date: null,
      completion_score: 0,
      priority: 1,
      leader: null,
      project: projectId,
      dashboard: activeDashboard.id,
    };
    dispatch(addTaskAsync(updatedTaskData));
  };

  return (
    <div className={styles.taskcard_add}
      onClick={() => handleAddNewTask(activeDashboard, projectId)}
    >
      <AddIcon />
    </div>
  );
};

const TaskContainer = ({ activeDashboard, projectId, showTaskContainer = true, setTaskStatus, show_completed_tasks = false }) => {
  const dispatch = useDispatch();
  const { tasks, isLoading, error } = useSelector(state => state.tasks);
  const tasksInProject = tasks.filter(item => item.project === projectId) || [];
 
  
  useEffect(() => {
    if (projectId) {
      dispatch(fetchTasksAsync(projectId));
    }
  }, [projectId, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    showTaskContainer && (
      <div className={styles.taskContainer}>
        <>
        {tasksInProject.length > 0 && tasksInProject.map(task_obj => {
          // if the task is marked as complete and the user is not viewing the completed tasks, don't show the task
          if (task_obj.completion_date === null && show_completed_tasks === false) {
            return (
              <TaskCard 
                key={task_obj.id}
                task_object={task_obj}
                setTaskStatus={setTaskStatus}
                taskId={task_obj.id}
                taskTitleProps = {task_obj.name}
                taskDescriptionProps = {task_obj.description}
                taskProgress = {task_obj.completion_score}
                taskLeader = {task_obj.leader}
                startDateProp = {task_obj.start_date}
                endDateProp = {task_obj.due_date}
                completionDateProp = {task_obj.completion_date}
                projectId= {task_obj.project}
                activeDashboard={activeDashboard}
              />
            );
          } else if (task_obj.completion_date !== null && show_completed_tasks === true) {
            return (
              <TaskCard 
                key={task_obj.id}
                task_object={task_obj}
                setTaskStatus={setTaskStatus}
                taskId={task_obj.id}
                taskTitleProps = {task_obj.name}
                taskDescriptionProps = {task_obj.description}
                taskProgress = {task_obj.completion_score}
                taskLeader = {task_obj.leader}
                startDateProp = {task_obj.start_date}
                endDateProp = {task_obj.due_date}
                completionDateProp = {task_obj.completion_date}
                projectId= {task_obj.project}
                activeDashboard={activeDashboard}
              />
            );
          } else {
            return null;
          }
        })}
        </>
        <>
        {show_completed_tasks === false && (
            <AddNewTaskCard 
              activeDashboard={activeDashboard}
              projectId={projectId}
            />
        )}
        </>
      </div>
    )
  );
};

export default TaskContainer;