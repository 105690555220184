import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import companiesReducer from '../slices/companiesSlice';
import dashboardsReducer from '../slices/dashboardsSlice';
import uiReducer from '../slices/uiSlice';
import activeCompanyReducer from '../slices/activeCompanySlice';
import activeDashboardReducer from '../slices/activeDashboardSlice';
import goalsReducer from '../slices/goalsSlice';
import businessSegmentsReducer from '../slices/businessSegmentsSlice';
import kpiHeadingsReducer from '../slices/kpiHeadingsSlice';
import kpisReducer from '../slices/kpiSlice';
import objectivesReducer from '../slices/objectivesSlice';
import projectsReducer from '../slices/projectsSlice';
import tasksReducer from '../slices/tasksSlice';
import userReducer from '../slices/userSlice';

const companyPersistConfig = {
  key: 'activeCompany',
  storage,
};

const dashboardPersistConfig = {
  key: 'activeDashboard',
  storage,
};

const persistedCompanyReducer = persistReducer(companyPersistConfig, activeCompanyReducer);
const persistedDashboardReducer = persistReducer(dashboardPersistConfig, activeDashboardReducer);

const appReducer = combineReducers({
  companies: companiesReducer,
  dashboards: dashboardsReducer,
  activeCompany: persistedCompanyReducer,
  activeDashboard: persistedDashboardReducer,
  businessSegments: businessSegmentsReducer,
  ui: uiReducer,
  goals: goalsReducer,
  kpiHeadings: kpiHeadingsReducer,
  kpis: kpisReducer,
  objectives: objectivesReducer,
  projects: projectsReducer,
  tasks: tasksReducer,
  user: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    const initialBusinessSegmentsState = businessSegmentsReducer(undefined, {});
    const initialUIState = uiReducer(undefined, {});
    const initialGoalsState = goalsReducer(undefined, {});
    const initialKPIHeadingsState = kpiHeadingsReducer(undefined, {});
    const initialKPIsState = kpisReducer(undefined, {});
    const initialObjectivesState = objectivesReducer(undefined, {});
    const initialProjectsState = projectsReducer(undefined, {});
    const initialTasksState = tasksReducer(undefined, {});

    return {
      ...state,
      businessSegments: initialBusinessSegmentsState,
      ui: initialUIState,
      goals: initialGoalsState,
      kpiHeadings: initialKPIHeadingsState,
      kpis: initialKPIsState,
      objectives: initialObjectivesState,
      projects: initialProjectsState,
      tasks: initialTasksState,
    };
  } else if (action.type === 'persist/REHYDRATE') {
    // Existing rehydrate logic
    if (state && state._persist) {
      const {_persist} = state;
      state = {...state, _persist};
    }
  } else if (action.type === 'PURGE_STATE') {
    const initialactiveCompanyState = persistedCompanyReducer(undefined, {});
    const initialactiveDashboardState = persistedDashboardReducer(undefined, {});
    localStorage.removeItem('persist:activeCompany');
    localStorage.removeItem('persist:activeDashboard');
    return {
      ...state,
      companies: companiesReducer(undefined, {}),
      dashboards: dashboardsReducer(undefined, {}),
      activeCompany: initialactiveCompanyState,
      activeDashboard: initialactiveDashboardState,
    };
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

export const persistor = persistStore(store);