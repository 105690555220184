import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addProjectAsync, deleteProjectAsync, updateProjectAsync } from '../redux/slices/projectsSlice';
import styles from "./css/ProjectAccordionHeader.module.css";
import TaskContainer from "./TaskContainer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';

const behindStyle = {
  color: 'red',
}
const onTrackStyle = {
  color: 'green',
}

const ProjectAccordionHeader = ({
  isEditingNew = false,
  onCancel,
  onProjectSaved,
  projectId,
  projectName,
  projectComment,
  taskContainerShow,
  activeDashboard,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(isEditingNew);
  const [taskStatus, setTaskStatus] = React.useState({});
  const totalOnTrackTasks = Object.values(taskStatus).filter(status => status === "On-Track").length;
  const totalBehindTasks = Object.values(taskStatus).filter(status => status === "Behind Schedule").length;
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);

  const [editedProject, setEditedProject] = useState({
    projectId: projectId || null,
    name: projectName || "Newly Added Project",
    description: projectComment || "Newly Added Description",
    leader: activeDashboard ? activeDashboard.owner.id : null,
    dashboard: activeDashboard ? activeDashboard.id : null,
  });

  // Inline styles for input fields
  const inputStyle = {
    alignSelf: 'stretch',
    borderRadius: 'var(--br-5xs)',
    display: 'flex',
    flexDirection: 'row',
    padding: 'var(--padding-8xs) var(--padding-8xs)',
    fontSize: '16px',
    color: 'var(--d42-text-color-1)',
    fontFamily: 'var(--h6-16px-regular-inter)',
    flexGrow: '1',
  };

  const handleSaveProject = async (event) => {
    event.stopPropagation();
    try {
      if (projectId) {
        await dispatch(updateProjectAsync({ projectId, updatedData: editedProject }));
        setIsEditing(false);
      } else {
        await dispatch(addProjectAsync(editedProject));
      }
      if (onProjectSaved) {
        onProjectSaved(editedProject);
      }
    } catch (error) {
      console.error("Failed to save project:", error);
    }
  };

  const handleDeleteProject = async () => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await dispatch(deleteProjectAsync(projectId));
      } catch (error) {
        console.error("Failed to delete project:", error);
      }
    }
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setAnchorEl(null);
  };

  const handleEscape = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsEditing(false);
      if (onCancel) {
        onCancel();
      }
    }
  }, [onCancel]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSaveProject(event);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedProject((prev) => ({ ...prev, [name]: value }));
  };

  const handleMenuOpen = (event) => {
    if (!isEditing){
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    if (!isEditing){
      setAnchorEl(null);
    }
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setIsEditing(false);
    if (onCancel) {
      onCancel();
    }
  }

  const handleSwitchChange = (event) => {
    setShowCompletedTasks(!showCompletedTasks);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [isEditing, handleEscape]);

  return (
    <div className={styles.projectAccordionHeader}>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={styles.projectAccordionHeader} style={{ width: '100%' }}>

              {isEditing ? (
                <>
                  <div className={styles.projectDeleter}>
                    <CheckIcon onClick={handleSaveProject} />
                  </div>
                  <div className={styles.projectDeleter}>
                    <CloseIcon onClick={handleCancel} />
                  </div>
                </>
              ) : (
                <div className={styles.projectDeleter}>
                  <MoreVert onClick={(e) => handleMenuOpen(e)} />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuItem onClick={handleSwitchChange}>
                      <Switch checked={showCompletedTasks} onChange={handleSwitchChange} /> Show Completed Tasks
                    </MenuItem>
                    <MenuItem onClick={(e) => handleEdit(e)}>
                      <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem onClick={handleDeleteProject}>
                      <DeleteForeverIcon /> Delete
                    </MenuItem>
                  </Menu>
                </div>
              )}
            <div className={styles.projectName_container}>
              {isEditing ? (
                <input
                  type="text"
                  value={editedProject.name}
                  onChange={handleInputChange}
                  name="name"
                  style={inputStyle}
                  onKeyDown={handleKeyPress}
                  onClick={(event) => event.stopPropagation()}
                />
              ) : (
                <div className={styles.projectName}>{projectName}</div>
              )}
            </div>
            <div className={styles.projectFocusComment}>
              {isEditing ? (
                <input
                  type="text"
                  value={editedProject.description}
                  onChange={handleInputChange}
                  name="description"
                  style={inputStyle}
                  onKeyDown={handleKeyPress}
                  onClick={(event) => event.stopPropagation()}
                />
              ) : (
                <div className={styles.projectFocus}>{projectComment}</div>
              )}
            </div>
            {!isEditing && (
            <div className={styles.projectScore}>
              <ErrorIcon style={behindStyle} />
              <b className={styles.b} style={behindStyle}> {totalBehindTasks} / </b>
              <CheckCircleIcon style={onTrackStyle} />
              <b className={styles.b} style={onTrackStyle}> {totalOnTrackTasks}</b>
            </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TaskContainer
            showTaskContainer={taskContainerShow}
            setTaskStatus={setTaskStatus}
            activeDashboard={activeDashboard}
            projectId={projectId}
            show_completed_tasks = {showCompletedTasks}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProjectAccordionHeader;
