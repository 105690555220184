import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import styles from './css/MainMenuComponent.module.css';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { fetchCompaniesAsync, createCompanyAsync } from '../redux/slices/companiesSlice';
import { CompanyCard } from './CompanyCard';
import { DashboardCard } from './DashboardCard';
import CreateCompanyDialog from './CreateCompanyDialog';
import ModifyCompanyDialog from './ModifyCompanyDialog';
import ManageUsersDialog from './ManageUsersDialog';
import CreateDashboardDialog from './CreateDashboardDialog';
import ModifyDashboardDialog from './ModifyDashboardDialog';
import ManageDashboardUsersDialog from './ManageDashboardUsersDialog';
import { Paper } from '@mui/material';


const MainMenuComponent = ({ 
    companies,
    activeCompany,
    dashboards,
    handleCompanySelected,
    onCompanyCreated,
    handleDashboardSelected,
 }) => {
    const dispatch = useDispatch();
    const [showCreateCompanyDialog, setShowCreateCompanyDialog] = useState(false);
    const [showModifyCompanyDialog, setShowModifyCompanyDialog] = useState(false);
    const [showManageUsersDialog, setShowManageUsersDialog] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [showCreateDashboardDialog, setShowCreateDashboardDialog] = useState(false);
    const [showModifyDashboardDialog, setShowModifyDashboardDialog] = useState(false);
    const [showManageDashboardUsersDialog, setShowManageDashboardUsersDialog] = useState(false); // To manage users
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    
    const handleCompanyCreated = (newCompany) => {
        dispatch(createCompanyAsync(newCompany));
        onCompanyCreated();
    };

    const handleEditCompany = (selectedCompany) => {
      setSelectedCompany(selectedCompany);
      setShowModifyCompanyDialog(true);
    };

    const handleModifyCompany = (modifiedCompany) => {     
      dispatch(fetchCompaniesAsync());
      setShowModifyCompanyDialog(false);
    };
    const onCompaneDeleted = () => {
      setSelectedCompany(null);
      dispatch(fetchCompaniesAsync());
    };
  
    const handleManageUsers = (company) => {
      setSelectedUsers(company.users);
      setSelectedCompany(company);
      setShowManageUsersDialog(true);
    };
  
    const handleRemovedUser = (company) => {
      setSelectedUsers(company.users);
      setSelectedCompany(company);
      dispatch(fetchCompaniesAsync());
    };
  
    const handleAddedUser = (company) => {
      setSelectedUsers(company.users);
      setSelectedCompany(company);
      dispatch(fetchCompaniesAsync());
    };

    const handleModifyDashboard = (dashboard) => {
      setSelectedDashboard(dashboard);
      setShowModifyDashboardDialog(true);
    };

    const handleDashboardModified = (modifiedDashboard) => {
        setShowModifyDashboardDialog(false);
    };

    const handleManageDashboardUsers = (dashboard) => {
        setSelectedDashboard(dashboard);
        setShowManageDashboardUsersDialog(true);
    };

    return (
        <>
        <ModifyCompanyDialog
            open={showModifyCompanyDialog}
            onClose={() => setShowModifyCompanyDialog(false)}
            onModify={handleModifyCompany}
            onDeleted={() => onCompaneDeleted()}
            company={selectedCompany}
          />
        <CreateCompanyDialog
          open={showCreateCompanyDialog}
          onClose={() => setShowCreateCompanyDialog(false)}
          onCompanyCreated={handleCompanyCreated}
        />
       <ManageUsersDialog
          open={showManageUsersDialog}
          onClose={() => setShowManageUsersDialog(false)}
          company={selectedCompany}
          users={selectedUsers}
          handleRemovedUser={handleRemovedUser}
          handleAddedUser={handleAddedUser}
        />
        
        <CreateDashboardDialog
            open={showCreateDashboardDialog}
            onClose={() => setShowCreateDashboardDialog(false)}
            onDashboardCreated={handleDashboardModified}
        />
        <ModifyDashboardDialog
            open={showModifyDashboardDialog}
            onClose={() => setShowModifyDashboardDialog(false)}
            dashboard={selectedDashboard}
            onDashboardModified={handleDashboardModified}
        />
        <ManageDashboardUsersDialog // Assuming this component exists
            open={showManageDashboardUsersDialog}
            onClose={() => setShowManageDashboardUsersDialog(false)}
            dashboard={selectedDashboard}
            company={activeCompany}
        />
        {companies && (
            <div className={styles.MainMenuContainer}>
              <div className={styles.companyContainer}>
                <b style={{ alignSelf: 'center' }}>Companies</b>
                <div className={styles.companySecondContainer}>
                  <div className={styles.companyHeader}>
                    <div className={styles.companycardContainer}>
                      {companies.map((company) => (
                        <CompanyCard 
                          key={company.id} 
                          companyName={company.name} 
                          companyTagline={company.tagline} 
                          handleCompanySelected={handleCompanySelected}
                          onEdit={handleEditCompany}
                          onEditUsers={handleManageUsers}
                          company_obj={company}
                        />
                      ))}
                      <Paper elevation={3} style={{ padding: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Card onClick={() => setShowCreateCompanyDialog(true)} className={styles.addCard}>
                          <Typography variant="h1">+</Typography>
                        </Card>
                      </Paper>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          )}
          
         {dashboards && (
            <div className={styles.MainMenuContainer}>
              <div className={styles.companyContainer}>
                <b style={{ alignSelf: 'center' }}>Dashboards</b>
                <div className={styles.companySecondContainer}>
                  <div className={styles.companyHeader}>
                    <div className={styles.companycardContainer}>
                      <>
                        {dashboards.map((dashboard) => (
                          <DashboardCard 
                            key={dashboard.id} 
                            handleDashboardSelected={handleDashboardSelected}
                            onEdit={handleModifyDashboard}
                            onEditUsers={handleManageDashboardUsers}
                            dashboard_obj={dashboard}
                          />
                        ))}
                        {activeCompany ? (
                          <Paper elevation={3} style={{ padding: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Card onClick={() => setShowCreateDashboardDialog(true)} className={styles.addCard}>
                              <Typography variant="h1">+</Typography>
                            </Card>
                          </Paper>
                        ) : (
                          <Paper elevation={3} style={{ padding: '32px', marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Card style={{border: '2px solid #c8c8c8', boxShadow: '-8px -3px 4px rgba(0, 0, 0, 0.23)'}}>
                              <Typography variant="h5">Select Company</Typography>
                            </Card>
                          </Paper>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
    );
};

export default MainMenuComponent;