import React, { useState } from 'react';
import { Dialog, DialogContent, TextField, Button, Paper, Typography, Container  } from '@mui/material';

const CreateCompanyDialog = ({ open, onClose, onCompanyCreated }) => {
  const [name, setName] = useState('');
  const [tagline, setTagline] = useState('');
  const [notes, setNotes] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    try {
      const companyData = { 
        name, 
        tagline, 
        notes, 
        address,
        users: [],
        invitations: [] };
      onCompanyCreated(companyData);
      // onClose();
    } catch (err) {
      setError('Failed to create company. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{ padding: '32px', marginTop: 64, marginBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Create New Company
          </Typography>
          <DialogContent>
            <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
              <TextField fullWidth required margin="normal" variant="outlined" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
              <TextField fullWidth required margin="normal" variant="outlined" label="Tagline" value={tagline} onChange={(e) => setTagline(e.target.value)} />
              <TextField fullWidth required margin="normal" variant="outlined" label="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
              <TextField fullWidth required margin="normal" variant="outlined" label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
              
              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
                Create
              </Button>
              {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
            </form>
          </DialogContent>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default CreateCompanyDialog;
