const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

export const fetchKPIData = async (kpiId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-data/?kpi_id=${kpiId}`, { 
        method: 'GET',
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new Error('Failed to fetch KPI data');
    }
    return await response.json();
};

export const addKPIData = async (kpiData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-data/`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(kpiData),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to add KPI data:", error);
        throw error;
    }
};

export const updateKPIData = async (updatedData) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-data/${updatedData.id}/`, { 
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(updatedData),
    });
    if (!response.ok) {
        throw new Error('Failed to update KPI data');
    }
    return await response.json();
};

export const deleteKPIData = async (kpiDataId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-data/${kpiDataId}/`, { 
        method: 'DELETE',
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new Error('Failed to delete KPI data');
    }
};