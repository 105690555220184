import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react';

const AddUserDialog = ({ open, onClose, onAddUser, company }) => {
  const [email, setEmail] = useState('');
   
  const handleAdd = () => {
    
    onAddUser(email);
    setEmail('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the user's email address to add them to the company.</DialogContentText>
        <TextField autoFocus margin="dense" label="Email Address" type="email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleAdd} color="primary">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;