import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginUser, logoutUser, fetchCurrentUser, updateUser, resetPassword, resetPasswordConfirm } from '../../api/userAPI';

// Async thunk for logging in
export const loginUserAsync = createAsyncThunk(
    'user/loginUser',
    async (userData) => {
        const data = await loginUser(userData);
        return data.access; 
    }
);

// Async thunk for logging out
export const logoutUserAsync = createAsyncThunk(
  'user/logoutUser',
  async () => {
    await logoutUser();
  }
);

//reset password
export const resetPasswordAsync = createAsyncThunk(
  'user/resetPassword',
  async (email) => {
    const data = await resetPassword(email);
    return data;
  }
);

export const resetPasswordConfirmAsync = createAsyncThunk(
  'user/resetPasswordConfirm',
  async (userData) => {
    const data = await resetPasswordConfirm(userData);
    return data;
  }
);

export const fetchCurrentUserAsync = createAsyncThunk(
  'user/fetchCurrentUser',
  async () => {
    const user = await fetchCurrentUser();
    return user;
  }
);

export const updateUserAsync = createAsyncThunk(
  'user/updateUser',
  async (userData) => {
    const user = await updateUser(userData);
    return user;
  }
);

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUserAsync.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(fetchCurrentUserAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export default userSlice.reducer;