import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTasks, addTask, updateTask, deleteTask } from '../../api/tasksAPI';

export const fetchTasksAsync = createAsyncThunk(
    'tasks/fetchTasks',
    async (projectId, { rejectWithValue }) => {
        try {
            const response = await fetchTasks(projectId);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addTaskAsync = createAsyncThunk(
    'tasks/addTask',
    async (newTask, { rejectWithValue }) => {
        try {
            const response = await addTask(newTask);
            const data = await response;
            return data; // Ensure the new Task is returned
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateTaskAsync = createAsyncThunk(
    'tasks/updateTask',
    async ({ taskId, updatedData }) => {
        const updatedTask = await updateTask(taskId, updatedData);
        return { taskId, updatedTask };
    }
);

export const deleteTaskAsync = createAsyncThunk(
    'tasks/deleteTask',
    async (taskId, { rejectWithValue }) => {
        try {
            await deleteTask(taskId);
            return taskId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    tasks: [],
    isLoading: false,
    error: null,
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTasksAsync.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchTasksAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                const newTasks = action.payload;
                const combinedTasks = [...state.tasks, ...newTasks];
                    // Eliminate duplicates
                    state.tasks = combinedTasks.filter((task, index, self) =>
                    index === self.findIndex((t) => t.id === task.id)
                );
            })
            .addCase(fetchTasksAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addTaskAsync.fulfilled, (state, action) => {
                state.tasks.push(action.payload);
            })
            .addCase(addTaskAsync.rejected, (state, action) => {
                // Handle the error
            })
            .addCase(updateTaskAsync.fulfilled, (state, action) => {
                const { taskId, updatedTask } = action.payload;
                const existingTask = state.tasks.find(task => task.id === taskId);
                if (existingTask) {
                    Object.assign(existingTask, updatedTask);
                }
            })
            .addCase(deleteTaskAsync.fulfilled, (state, action) => {
                state.tasks = state.tasks.filter(task => task.id !== action.payload);
            });
    },
});

export default tasksSlice.reducer;