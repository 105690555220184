import styles from "./css/KPIData.module.css";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const KPIData = ({ kpiNowValue, kpiProgress, kpiLinkURL = null }) => {
  return (
    <div className={styles.kpiDataContainer}>
      <div className={styles.kpiDataRow}>
        <div className={styles.kpiData}>
          <div className={styles.div}>{kpiNowValue}</div>
        </div>
        {kpiLinkURL && (
          <div className={styles.kpiLink}>
            <OpenInNewIcon 
            fontSize="small"
            onClick={(event) => {
              event.stopPropagation();
              window.open(kpiLinkURL, "_blank");
            }} />
          </div>
        )}
        <div className={styles.kpiProgress}>
          <div className={styles.div1}>{kpiProgress} %</div>
        </div>
      </div>
    </div>    
  );
};

export default KPIData;