export const fetchKPIsByHeadingId = async (kpiheading_id) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpis/?kpiheading_id=${kpiheading_id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    const data = await response.json();
    if (response.ok) {
        return data;
    } else {
        throw new Error(data.error || 'Failed to fetch KPIs.');
    }
};

export const addKPI = async (kpiData) => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpis/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(kpiData),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to save business segment:", error);
        throw error;
    }
};

export const updateKPI = async (kpiId, updatedData) => {
    const token = localStorage.getItem('access_token');
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpis/${kpiId}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
        });
        
        if (!response.ok) {
            const errorBody = await response.json(); // Parse the JSON error body
            throw new Error(errorBody.link ? errorBody.link.join(', ') : `HTTP error! Status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
}

export const deleteKPI = async (kpiId) => {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpis/${kpiId}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to delete KPI.');
    }
    return response;
  };