import styles from "./css/CompanyMainLogo.module.css";
import { Link } from 'react-router-dom';

const CompanyMainLogo = ({
  companyLogo = "/color-logo-with-background-1@2x.png",
}) => {
  return (
    <div className={styles.mainLogo}>
      <div className={styles.logo}>
        <Link to="/"> {/* Navigate to the main menu route */}
          <img
            className={styles.colorLogoWithBackground1}
            alt=""
            src={companyLogo}
          />
        </Link>
      </div>
    </div>
  );
};

export default CompanyMainLogo;
