import React, { useState } from 'react';
import { Button, TextField, Paper, Typography, Container, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordConfirmAsync } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

const PasswordReset = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    token: token,
    password1: '',
    password2: '',

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(resetPasswordConfirmAsync(formData));
      // if data contains error message, set message state to the error message
      if (data && data.error && data.error.message) {
        setMessage('An error occurred: ' + data.error.message);
        return;
      }
      // if response is ok, set message state to success message
      setMessage('Your password has been reset successfully.');

      // if data does not contain error message, redirect to dashboard and add the message to the location state
      navigate('/login', { state: { message: 'Your password has been reset successfully.' } });
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/login');
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '32px', marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth label="Password" name="password1" type="password" onChange={handleChange} variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Confirm Password" name="password2" type="password" onChange={handleChange} variant="outlined" />
                </Grid>
                
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                    Reset Password
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleCancel} fullWidth>
                    Cancel
                    </Button>
                </Grid>
            </Grid>
          <br /><br />
          {message && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{message}</Typography>}
        </form>
      </Paper>
    </Container>
  );
};

export default PasswordReset;
