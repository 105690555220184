import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBusinessSegmentsAsync } from '../redux/slices/businessSegmentsSlice';
import BusinessSegment from "./BusinessSegment";
import styles from "./css/BusinessSegmentContainer.module.css";
import AddIcon from '@mui/icons-material/Add';

const BusinessSegmentContainer = ({ activeDashboard }) => {
  const dispatch = useDispatch();
  const { businessSegments, isLoading, error } = useSelector(state => state.businessSegments);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (activeDashboard && activeDashboard.id) {
      dispatch(fetchBusinessSegmentsAsync(activeDashboard.id));
    }
  }, [dispatch, activeDashboard]);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
  };

  const handleSegmentSaved = () => {
    setIsAdding(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.businessSegmentContainer}>
      {businessSegments && businessSegments.map(segment => (
        <BusinessSegment
          key={segment.id}
          segmentId={segment.id}
          businessSegmentName={segment.name}
          segmentFocusComment={segment.focus_comment}
          activeDashboard={activeDashboard}
        />
      ))}
      {isAdding ? (
        <BusinessSegment
          isEditingNew={true}
          onCancel={handleCancelAdd}
          onSegmentSaved={handleSegmentSaved}
          activeDashboard={activeDashboard}
        />
      ) : (
        <div className={styles.businessSegmentContainer_add} onClick={handleAddClick}>
          <AddIcon />
        </div>
      )}
    </div>
  );
};

export default BusinessSegmentContainer;
