import React, { useState, useEffect } from 'react';
import { Button, TextField, Paper, Typography, Container } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { loginUserAsync } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Check if there is a state in the location object and set the message
    if (location.state && location.state.message) {
      setMessage(location.state.message);
      // Clear the state so it doesn't show the message again if the user navigates away and back
      location.state = null;
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(loginUserAsync({ email, password }));
      // if data contains error message, set message state to the error message
      if (data && data.error && data.error.message) {
        setMessage('An error occurred: ' + data.error.message);
        return;
      }
      // if data does not contain error message, redirect to dashboard
      navigate('/dashboard');
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };
  
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '32px', marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
          <TextField 
            fullWidth 
            margin="normal"
            label="Email" 
            name="email" 
            type="email" 
            variant="outlined" 
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
            Sign In
          </Button>
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <br />
            <MuiLink component={Link} to="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </MuiLink>
            <br /><br />
            <MuiLink component={Link} to="/forgotpassword" variant="body2">
              {"Forgot your password?"}
            </MuiLink>
          </div>
          {message && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{message}</Typography>}
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
