import { useEffect, useState } from 'react';
import ProjectAccordionHeader from "./ProjectAccordionHeader";
import styles from './css/ProjectTaskContainer.module.css';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjectsAsync } from '../redux/slices/projectsSlice';

const ProjectTaskContainer = ({ activeDashboard }) => {
  const dispatch = useDispatch();
  const { projects, isLoading, error } = useSelector(state => state.projects);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if(activeDashboard && activeDashboard.id) {
      dispatch(fetchProjectsAsync(activeDashboard.id));
    }
  }, [activeDashboard, dispatch]);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
  };

  const handleProjectSaved = () => {
    setIsAdding(false);
  };


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.projectTaskContainer}>
      {projects.map(project_obj => (
        <div key={project_obj.id} className={styles.projectContainer}>
          <div key={project_obj.id} className={styles.projectHeaderContainer}>
            <ProjectAccordionHeader
              key={project_obj.id}
              projectId={project_obj.id}
              projectName={project_obj.name}
              projectComment={project_obj.description}
              projectLeader={project_obj.leader}
              activeDashboard={activeDashboard}
              taskContainerShow={true} 
            />
          </div>
        </div>
      ))}
      {isAdding ? (
        <div key={-1} className={styles.projectContainer}>
          <div key={-1} className={styles.projectHeaderContainer}>
          <ProjectAccordionHeader
            isEditingNew={true}
            onCancel={handleCancelAdd}
            onProjectSaved={handleProjectSaved}
            activeDashboard={activeDashboard}
          />
          </div>
        </div>
      ) : (
        <div className={styles.projectTaskContainer_add} onClick={handleAddClick}>
          <AddIcon />
        </div>
      )}
    </div>
  );
};

export default ProjectTaskContainer;
