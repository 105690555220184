import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import styles from "./css/KPIDetailsComponent.module.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KPIDetailData from "./KPIDetailData";
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { updateKPIAsync, deleteKPIAsync } from '../redux/slices/kpiSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { addKPIDataAsync } from '../redux/slices/kpiSlice';

// New component for the "Add New KPI Data" card
const AddNewKPIDataCard = ({ onAdd, onEditLink }) => {
  return (
      <div className={styles.taskCardAdd}>
          <div className={styles.groupParent}>
              <div className={styles.addNewKPIDataTextParent}>
                  <Button
                      size="small"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={onAdd}
                  >
                      Add Data
                  </Button>
                  <Button
                      size="small"
                      variant="outlined"
                      startIcon={<LinkIcon />}
                      onClick={onEditLink}
                  >
                      Connect
                  </Button>
              </div>
          </div>
      </div>
  );
};

const KPIDetailsComponent = ({ kpi_object, onClose }) => {
  const kpiId = kpi_object.id;
  const kpiName = kpi_object.name;
  const kpiDescription = kpi_object.description;
  const kpiFrequency = kpi_object.frequency;
  const kpiMinValue = kpi_object.min_value;
  const kpiMaxValue = kpi_object.max_value;
  const kpiDataUnit = kpi_object.unit;
  const kpiData = kpi_object.kpiData;
  const kpiLink = kpi_object.link ? kpi_object.link : "";
  const kpiDashboard = useState(kpi_object.dashboard);
 
  const [selectedFrequency, setSelectedFrequency] = useState(kpiFrequency);

  // Add new state variables for editable min, max, and unit
  const [editableMin, setEditableMin] = useState(kpiMinValue);
  const [editableMax, setEditableMax] = useState(kpiMaxValue);
  const [editableUnit, setEditableUnit] = useState(kpiDataUnit);

  // Temporary states
  const [tempMin, setTempMin] = useState(kpiMinValue);
  const [tempMax, setTempMax] = useState(kpiMaxValue);
  const [tempUnit, setTempUnit] = useState(kpiDataUnit);

  // Edit states
  const [isMinEditable, setIsMinEditable] = useState(false);
  const [isMaxEditable, setIsMaxEditable] = useState(false);
  const [isUnitEditable, setIsUnitEditable] = useState(false);

  const [inputWidthMin, setInputWidthMin] = useState(0);
  const [inputWidthMax, setInputWidthMax] = useState(0);
  const [inputWidthUnit, setInputWidthUnit] = useState(0);

  const spanRefMin = useRef(null);
  const spanRefMax = useRef(null);
  const spanRefUnit = useRef(null);
  const textDivRefDescription = useRef(null);

  const [numLinesDescription, setNumLinesDescription] = useState(1);

  // Add new state variables for editable name and description
  const [editableName, setEditableName] = useState(kpiName);
  const [editableDescription, setEditableDescription] = useState(kpiDescription);

  const [tempName, setTempName] = useState(kpiName);
  const [tempDescription, setTempDescription] = useState(kpiDescription);

  const [isNameEditable, setIsNameEditable] = useState(false);
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);

  const [mostRecentKPIDataValue, setMostRecentKPIDataValue] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  const [kpiDetailDataArray, setKpiDetailDataArray] = useState([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState(kpiLink);
  const [errorMessage, setErrorMessage] = useState('');
  
  const dispatch = useDispatch();
  


  const spanRefName = useRef(null);
  const spanRefDescription = useRef(null);

  const inputStyle = {
    border: 'none',
    outline: 'none',
    font: 'inherit',
    padding: '0',
  };

  const inputStyleDescription = {
    border: 'none',
    outline: 'none',
    font: 'inherit',
    padding: '0',
    fontSize: 'var(--font-size-mini)',
    alignSelf: 'stretch',
    position: 'relative',
    lineHeight: '130%',
  }

  const inputStyleKpiName = {
    border: 'none',
    outline: 'none',
    font: 'inherit',
    padding: '0',
    alignSelf: 'stretch',
    position: 'relative',
    lineHeight: '130%',
    fontWeight: '500',
  };

  const handleMainDialogClick = (event) => {
    event.stopPropagation();
  }

  const handleFrequencyClick = (frequency) => {
    setSelectedFrequency(frequency);
    const updatedKPI = {...kpi_object};
    updatedKPI.frequency = frequency;
    dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }));
  };

  const handleKeyPress = (event, type, confirm, cancel) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      confirm();
    } else if (event.key === 'Escape') {
      event.preventDefault();
      cancel();
    }
  };

  const confirmMinChange = () => {
    setEditableMin(tempMin);
    setIsMinEditable(false);

    const updatedKPI = {...kpi_object};
    updatedKPI.min_value = Number(tempMin);
    dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }));
  };

  const confirmMaxChange = () => {
    setEditableMax(tempMax);
    setIsMaxEditable(false);
    const updatedKPI = {...kpi_object};
    updatedKPI.max_value = Number(tempMax);
    dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }));
  };

  const confirmUnitChange = () => {
    setEditableUnit(tempUnit);
    setIsUnitEditable(false);
    const updatedKPI = {...kpi_object};
    updatedKPI.unit = tempUnit;
    dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }));
  };

  const cancelMinChange = () => setTempMin(editableMin);
  const cancelMaxChange = () => setTempMax(editableMax);
  const cancelUnitChange = () => setTempUnit(editableUnit);


  const confirmNameChange = () => {
    setEditableName(tempName);
    setIsNameEditable(false);
    const updatedKPI = {...kpi_object};
    updatedKPI.name = tempName;
    dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }));
  };

  const confirmDescriptionChange = () => {
    setEditableDescription(tempDescription);
    setIsDescriptionEditable(false);
    const updatedKPI = {...kpi_object};
    updatedKPI.description = tempDescription;
    dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }));
  };

  const handleDescriptionChange = (e) => {
    setTempDescription(e.target.value);
    // Recalculate the number of rows
    const textArea = e.target;
    textArea.rows = 1; // Temporarily set rows to 1
    textArea.rows = textArea.scrollHeight / parseInt(getComputedStyle(textArea).lineHeight);
    setNumLinesDescription(textArea.rows);
  };

  const handleDeleteClick = () => {
    // Open the dialog
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    // Close the dialog
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    // Dispatch action to delete the KPI
    dispatch(deleteKPIAsync(kpiId));
    handleCloseDialog();
  };

  const handleAddKPIData = (kpiDashboardId) => {
    // Define the structure for new KPI data
    const newKPIData = {
        kpi: kpiId, // ID of the KPI to which this data belongs
        value: 0, // Initial value, can be set to a default or calculated
        note: 'new note', // Initial note, can be empty or a default value
        dashboard: kpiDashboardId[0],
    };
    dispatch(addKPIDataAsync(newKPIData));
};

const handleClose = () => {
  if (onClose) {
    onClose();
  }
};


const handleLinkDialogOpen = () => {
  setErrorMessage('');
  setLinkDialogOpen(true);
};

const handleLinkDialogClose = () => {
  setLinkUrl(kpiLink);
  setLinkDialogOpen(false);
};

const handleLinkSave = () => {
  const updatedKPI = { ...kpi_object };
  updatedKPI.link = linkUrl;

  // Dispatch action to update the KPI and check for response
  dispatch(updateKPIAsync({ kpiId: kpi_object.id, updatedData: updatedKPI }))
    .then((result) => {
      if (result.meta.requestStatus === 'rejected') {
        // Assuming the error message is directly in the payload as a string.
        // Adjust this based on the actual structure of your error payload.
        const errorMessage = result.payload ? result.payload : 'Error updating KPI link';
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage('');
        setLinkDialogOpen(false);
      }
    })
    .catch((error) => {
      // This catch block is for any errors thrown before the action is dispatched or if the dispatch itself fails
      setErrorMessage('Unexpected error occurred');
    });
};


const handleLinkChange = (event) => {
  setLinkUrl(event.target.value);
};

  // Set input width of Min based on content
  useEffect(() => {
    if (spanRefMin.current && isMinEditable) {
      setInputWidthMin(spanRefMin.current.offsetWidth + 5);
    }
  }, [tempMin, isMinEditable]);

    // Set input width of Max based on content
    useEffect(() => {
      if (spanRefMax.current && isMaxEditable) {
        setInputWidthMax(spanRefMax.current.offsetWidth + 5);
      }
    }, [tempMax, isMaxEditable]);
  
    // Set input width of Unit based on content
    useEffect(() => {
      if (spanRefUnit.current && isUnitEditable) {
        setInputWidthUnit(spanRefUnit.current.offsetWidth + 5);
      }
    }, [tempUnit, isUnitEditable]);


    useEffect(() => {
      const min = parseFloat(editableMin);
      const max = parseFloat(editableMax);
      const currentValue = parseFloat(mostRecentKPIDataValue);
    
      let progress = ((currentValue - min) / (max - min)) * 100;
      progress = Math.min(Math.max(progress, 0), 100); // Clamp between 0 and 100
    
      setProgressValue(progress);
    }, [editableMin, editableMax, mostRecentKPIDataValue]);

    useEffect(() => {
        let apiData = kpiData && kpiData.map(data => {
            return {
                id: data.id,
                kpiDataValue: data.value,
                kpiDataNote: data.note,
                kpiDataDate: data.date_created,
                dashboard: data.dashboard,
            };
        });
        setKpiDetailDataArray(apiData);
    }, [kpiData]);

    useEffect(() => {
      if (kpiDetailDataArray && kpiDetailDataArray.length === 0) return;
    
      const mostRecentData = kpiDetailDataArray && kpiDetailDataArray.reduce((mostRecent, current) => {
        return new Date(current.kpiDataDate) > new Date(mostRecent.kpiDataDate) ? current : mostRecent;
      });
      if (!mostRecentData) return;
      setMostRecentKPIDataValue(mostRecentData.kpiDataValue);
    }, [kpiDetailDataArray]);
    

    
  return (
    <div className={styles.kpiDetailsComponent} onClick={handleMainDialogClick}>
      <CloseIcon className={styles.closeIcon} onClick={handleClose} />
      {kpi_object.link && (
      <OpenInNewIcon className={styles.openInNewWindowIcon} onClick={() => window.open(linkUrl, '_blank')} />
      )}
      <div className={styles.frameParent}>
        <div className={styles.kpiNameDesignationParent}>
          <span ref={spanRefName} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
            {tempName}
          </span>
          {isNameEditable ? (
            <input 
              autoFocus
              type="text"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              onBlur={() => {confirmNameChange();}}
              onKeyDown={(e) => handleKeyPress(e, 'name', confirmNameChange)}
              style={{ ...inputStyleKpiName}}
              onMouseDown={(e) => e.stopPropagation()}
            />         
          ) : (
            <div
              className={styles.kpiName} 
              onClick={() => { setTempName(editableName); setIsNameEditable(true); }}>
              {editableName}
            </div>
          )}

          <span ref={spanRefDescription} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
            {tempDescription}
          </span>
          {isDescriptionEditable ? (
            <textarea 
              autoFocus
              ref={textDivRefDescription}
              rows={numLinesDescription}
              value={tempDescription}
              onChange={handleDescriptionChange}
              onBlur={() => {confirmDescriptionChange();}}
              onKeyDown={(e) => handleKeyPress(e, 'description', confirmDescriptionChange)}
              style={{ ...inputStyleDescription}}
              onMouseDown={(e) => e.stopPropagation()}
            />
          ) : (
            <div 
              className={styles.unitinputlabel} 
              onClick={() => { setTempDescription(editableDescription); setIsDescriptionEditable(true); }}>
              {editableDescription}
            </div>
          )}
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.mininputlabelParent}>
              <div className={styles.mininputlabel}>{`Start: `}</div>
              <div className={styles.mininputlabel}>{`Target: `}</div>
              <div className={styles.mininputlabel}>Unit:</div>
            </div>
            <div className={styles.mininputParent}>
              <span ref={spanRefMin} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
                {tempMin}
              </span>
              {isMinEditable ? (
                <input 
                  autoFocus
                  type="text"
                  value={tempMin}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) ) {
                      setTempMin(value);
                    } else {
                      setTempMin(editableMin);
                    }
                  }}
                  onBlur={() => {confirmMinChange();}}
                  onKeyDown={(e) => handleKeyPress(e, 'min', confirmMinChange, cancelMinChange)}
                  style={{ ...inputStyle, width: `${inputWidthMin}px` }}
                  onMouseDown={(e) => e.stopPropagation()}
                />
                
              ) : (
                <div onClick={() => { setTempMin(editableMin); setIsMinEditable(true); }}>
                  {editableMin}
                </div>
              )}
              <span ref={spanRefMax} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
                {tempMax}
              </span>
              {isMaxEditable ? (
                <input 
                  autoFocus
                  type="text"
                  value={tempMax}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) && value !== "-") {
                      setTempMax(value);
                    } else {
                      setTempMax(editableMax);
                    }
                  }}
                  onBlur={() => {confirmMaxChange();}}
                  onKeyDown={(e) => handleKeyPress(e, 'max', confirmMaxChange, cancelMaxChange)}
                  style={{ ...inputStyle, width: `${inputWidthMax}px` }}
                  onMouseDown={(e) => e.stopPropagation()}
                />
              ) : (
                <div onClick={() => { setTempMax(editableMax); setIsMaxEditable(true); }}>
                  {editableMax}
                </div>
              )}
              <span ref={spanRefUnit} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
                {tempUnit}
              </span>
              {isUnitEditable ? (
                <input 
                  autoFocus
                  type="text"
                  value={tempUnit}
                  onChange={(e) => setTempUnit(e.target.value)}
                  onBlur={() => {confirmUnitChange();}}
                  onKeyDown={(e) => handleKeyPress(e, 'unit', confirmUnitChange, cancelUnitChange)}
                  style={{ ...inputStyle, width: `${inputWidthUnit}px` }}
                  onMouseDown={(e) => e.stopPropagation()}
                />
              ) : (
                <div onClick={() => { setTempUnit(editableUnit); setIsUnitEditable(true); }}>
                  {editableUnit}
                </div>
              )}
            </div>
          </div>
          <div className={styles.kpiFrequencyContainerParent}>
            <div
              className={`${styles.kpiFrequencyContainer} ${
                selectedFrequency === "Weekly"
                  ? styles.kpiFrequencyContainer_selected
                  : ""
              }`}
              onClick={() => handleFrequencyClick("Weekly")}
            >
              <div className={styles.Weekly}>Weekly</div>
            </div>
            <div
              className={`${styles.kpiFrequencyContainer} ${
                selectedFrequency === "Monthly"
                  ? styles.kpiFrequencyContainer_selected
                  : ""
              }`}
              onClick={() => handleFrequencyClick("Monthly")}
            >
              <div className={styles.Weekly}>Monthly</div>
            </div>
            <div
              className={`${styles.kpiFrequencyContainer} ${
                selectedFrequency === "Quarterly"
                  ? styles.kpiFrequencyContainer_selected
                  : ""
              }`}
              onClick={() => handleFrequencyClick("Quarterly")}
            >
              <div className={styles.Weekly}>Quarterly</div>
            </div>
          </div>
          <AddNewKPIDataCard onAdd={() => handleAddKPIData(kpiDashboard)} onEditLink={() => handleLinkDialogOpen()  } />
          <div className={styles.deleteKpiButtonWrapper}>
            <div className={styles.deleteKpiButton}>
              <DeleteForeverIcon className={styles.trash31Icon} onClick={handleDeleteClick}/>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.progressTextparent}>
          <div className={styles.div}>0</div>
          <div className={styles.progress}>Progress [%]</div>
          <div className={styles.div1}>100</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            color: "#3f51b5",
            backgroundColor: "#e0e0e0", 
            width: '100%',
          }}
        />
      </div>
      <div className={styles.kpiDetailsComponentInner}>
        <div className={styles.frameWrapper}>
          {kpiDetailDataArray && 
            [...kpiDetailDataArray]
              .reverse()
              .map((kpiDetail, index) => (
                <KPIDetailData
                  key={kpiDetail.id}
                  kpiId = {kpiId}
                  kpiDataId={kpiDetail.id}
                  kpiDataValue={kpiDetail.kpiDataValue}
                  kpiDataUnit={editableUnit}
                  kpiDataNote={kpiDetail.kpiDataNote}
                  kpiDataDate={kpiDetail.kpiDataDate}
                  dashboard={kpiDetail.dashboard}
                />
          ))}
        </div>
      </div>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this KPI?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the link you want to connect to.
          </DialogContentText>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Link"
            type="url"
            fullWidth
            value={linkUrl}
            onChange={handleLinkChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLinkDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLinkSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default KPIDetailsComponent;