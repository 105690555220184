import React from 'react';
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/LoginComponent';
import Signup from './components/SignupComponent';
import Dashboard from './pages/Dashboard';
import VerificationFailureComponent from './components/VerificationFailureComponent';
import EmailVerification from './components/EmailVerification';
import ForgotPasswordComponent from './components/ForgotPasswordComponent';
import PasswordReset from './components/PasswordReset';

const isAuthenticated = () => {
  // Here, you may want to get authentication state from Redux instead
  const token = localStorage.getItem('access_token');
  const expirationTime = localStorage.getItem('token_expiration');
  if (!token || !expirationTime) return false;
  return new Date().getTime() < Number(expirationTime);
};

if (!isAuthenticated()) {
  localStorage.removeItem('access_token');
  localStorage.removeItem('token_expiration');
}

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPasswordComponent />} />
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/verify-email-failure" element={<VerificationFailureComponent />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />
        <Route path="/reset-password-confirm/:token" element={<PasswordReset />} />
      </Routes>
    </Router>
  );
}

export default App;