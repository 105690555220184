import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: null,
};

export const activeCompanySlice = createSlice({
  name: 'activeCompany',
  initialState,
  reducers: {
    setActiveCompany: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { setActiveCompany } = activeCompanySlice.actions;

export default activeCompanySlice.reducer;