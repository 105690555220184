const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

export const fetchKPIHeadings = async (businesssegmentId ) => {
  
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-headings/?business_segment_id=${businesssegmentId}`, {
    method: 'GET',
    headers: getHeaders(),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch KPI headings.');
  }
  return await response.json();
};

export const addKPIHeading = async (newHeadingData) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-headings/`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(newHeadingData),
  });
  if (!response.ok) {
    throw new Error('Failed to add KPI heading.');
  }
  return await response.json();
};

export const updateKPIHeading = async (kpiHeadingId, updatedData) => {
  try {
    
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-headings/${kpiHeadingId}/`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Failed to update KPI heading (A). Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to update KPI heading (B):", error);
    throw error;
  }
};

export const deleteKPIHeading = async (kpiHeadingId) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/kpi-headings/${kpiHeadingId}/`, {
      method: 'DELETE',
      headers: getHeaders(),
  });

  if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to delete KPI heading.');
  }
};
