import React, { useState } from 'react';
import KPIHeader from "./KPIHeader";
import KPIData from "./KPIData";
import KPIHistory from "./KPIHistory";
import KPIDetailsComponent from "./KPIDetailsComponent";
import styles from "./css/KPI.module.css";
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';


const KPI = ({ kpi_object }) => {
  const [showDetails, setShowDetails] = useState(false);

  const kpiDataExists = kpi_object.kpiData !== undefined;
  const kpiNowValue = kpiDataExists && kpi_object.kpiData.length > 0 ? kpi_object.kpiData[kpi_object.kpiData.length - 1].value : 'N/A';
  const latestKPIData = kpiDataExists && kpi_object.kpiData.length > 0 ? kpi_object.kpiData[kpi_object.kpiData.length - 1] : null;
  const latestKPIDate = latestKPIData ? new Date(latestKPIData.date_created) : null;

  // Calculate current progress
  const kpiProgress = kpiDataExists && kpi_object.kpiData.length > 0 ? ((kpiNowValue - kpi_object.min_value) / (kpi_object.max_value - kpi_object.min_value)) * 100 : 0;

  // Function to convert a value into a percentage
  const valueToPercentage = (value) => {
    let progress;

    if (kpi_object.min_value < kpi_object.max_value) {
        // Normal scenario
        progress = ((value - kpi_object.min_value) / (kpi_object.max_value - kpi_object.min_value)) * 100;
    } else {
        // Reverse scenario
        progress = ((kpi_object.min_value - value) / (kpi_object.min_value - kpi_object.max_value)) * 100;
    }

    return progress;
  };

  // Transform kpiHistory into percentages
  const kpiHistory = kpiDataExists && kpi_object.kpiData.length > 0 
    ? kpi_object.kpiData
        .slice(kpi_object.kpiData.length > 4 ? kpi_object.kpiData.length - 5 : 0, kpi_object.kpiData.length)
        .map(data => valueToPercentage(data.value)) 
    : 'N/A';

  const toggleDetails = () => {
    setShowDetails(true);
  };

  // Function to calculate next due date
  const calculateNextDueDate = (latestDate, frequency) => {
    const nextDueDate = new Date(latestDate);
    switch (frequency) {
      case 'Weekly':
        nextDueDate.setDate(nextDueDate.getDate() + 7);
        break;
      case 'Monthly':
        nextDueDate.setMonth(nextDueDate.getMonth() + 1);
        break;
      case 'Quarterly':
        nextDueDate.setMonth(nextDueDate.getMonth() + 3);
        break;
      // Add more cases as per your frequency types
      default:
        break;
    }
    return nextDueDate;
  };

  // Check if KPI is due
  const isKPIDue = latestKPIDate ? calculateNextDueDate(latestKPIDate, kpi_object.frequency) <= new Date() : false;

  const calculateDueProgress = (latestDate, nextDueDate) => {
    const currentTime = new Date();
    const totalInterval = nextDueDate.getTime() - latestDate.getTime();
    const elapsedInterval = currentTime.getTime() - latestDate.getTime();
    const progress = (elapsedInterval / totalInterval) * 100;
    return progress; //Math.min(Math.max(progress, 0), 200); // Clamp between 0 and 100
  };

    // Determine the next due date
    const nextDueDate = latestKPIDate ? calculateNextDueDate(latestKPIDate, kpi_object.frequency) : new Date();

    // Calculate the due progress
    const kpiDueProgress = latestKPIDate ? calculateDueProgress(latestKPIDate, nextDueDate) : 0;

    const regularProgress = Math.min(kpiDueProgress, 100);
    const overdueProgress = Math.max(kpiDueProgress - 100, 0);
     // Calculate the percentage of the overdue part relative to the total bar length
    const overduePercentage = (overdueProgress / (100 + overdueProgress)) * 100;

  return (
    <>
      <div className={styles.kpiContainer} onClick={toggleDetails}>
        
        <KPIHeader kpiName={kpi_object.name} kpiFrequency={kpi_object.frequency} isKPIDue={isKPIDue}/>
        <LinearProgress 
          variant="buffer"
          value={overduePercentage} // Fill the entire bar
          valueBuffer={regularProgress}
          sx={{
            width: '100%',
            '& .MuiLinearProgress-bar1Buffer': { backgroundColor: 'red' }, // Overdue color
            '& .MuiLinearProgress-bar2Buffer': { backgroundColor: 'blue' }, // Regular progress color
          }}
        />
        <KPIHistory kpiHistory={kpiHistory} />
        <KPIData kpiNowValue={Number(kpiNowValue).toFixed(1)} kpiProgress={Number(kpiProgress).toFixed(1)} kpiLinkURL={kpi_object.link} />
      </div>
        <Dialog
        open={showDetails}
        onClose={() => setShowDetails(false)}
        aria-labelledby="kpi-details-dialog"
        PaperProps={{
          className: styles.dialogPaper
        }}
      >
        <KPIDetailsComponent 
          kpi_object={kpi_object}
          onClose={() => setShowDetails(false)}
        />
      </Dialog>
    </>
  );
};

export default KPI;