const getHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
};

// Fetch Goals
export const fetchGoals = async (dashboardId) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/goals/?dashboard_id=${dashboardId}`, {
      method: 'GET',
      headers: getHeaders(),
  });
  if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to fetch goals');
  }
  return await response.json();
};

// Add Goal
export const addGoal = async (goalData) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/goals/`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(goalData),
  });
  if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to add goal');
  }
  return await response.json();
};

// Edit Goal
export const editGoal = async (goalId, updatedData) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/goals/${goalId}/`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(updatedData),
  });
  if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to edit goal');
  }
  return await response.json();
};

// Delete Goal
export const deleteGoal = async (goalId) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/goals/${goalId}/`, {
      method: 'DELETE',
      headers: getHeaders(),
  });
  if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to delete goal');
  }
};
