import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, TextField, Button, Paper, Typography, Container } from '@mui/material';
import { createDashboardAsync } from '../redux/slices/dashboardsSlice';

const CreateDashboardDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const activeCompany = useSelector(state => state.activeCompany.current);
  
  const [dashboardName, setDashboardName] = useState('');
  const [dashboardDescription, setDashboardDescription] = useState('');
  const [dashboardNotes, setDashboardNotes] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const dashboardData = {
        name: dashboardName,
        description: dashboardDescription,
        notes: dashboardNotes,
        company: activeCompany.id,
        users: []
      };

      // Using Redux action to create a new dashboard
      dispatch(createDashboardAsync(dashboardData));
      
      onClose();
    } catch (err) {
      setError('Failed to create dashboard. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{ padding: '32px', marginTop: 64, marginBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Create New Dashboard
          </Typography>
          <DialogContent>
            <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
              <TextField fullWidth margin="normal" variant="outlined" label="Name" value={dashboardName} onChange={(e) => setDashboardName(e.target.value)} required />
              <TextField fullWidth margin="normal" variant="outlined" label="Description" value={dashboardDescription} onChange={(e) => setDashboardDescription(e.target.value)} required />
              <TextField fullWidth margin="normal" variant="outlined" label="Notes" value={dashboardNotes} onChange={(e) => setDashboardNotes(e.target.value)} required />
              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '24px' }}>
                Create
              </Button>
              {error && <Typography color="error" style={{ textAlign: 'center', marginTop: '16px' }}>{error}</Typography>}
            </form>
          </DialogContent>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default CreateDashboardDialog;