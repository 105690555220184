import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addBusinessSegmentAsync, deleteBusinessSegmentAsync, updateBusinessSegmentAsync  } from '../redux/slices/businessSegmentsSlice';
import styles from "./css/SegmentContainer.module.css";
import SegmentObjectiveContainer from "./SegmentObjectiveContainer";
import SegmentDataContainer from "./SegmentDataContainer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { fetchBusinessSegmentsAsync } from '../redux/slices/businessSegmentsSlice';

const useSegmentActions = (dispatch, segmentId, editedSegment, onSegmentSaved, setIsEditing) => {
  const handleSaveSegment = async (event) => {
    event.stopPropagation();
    try {
      if (segmentId) {
        await dispatch(updateBusinessSegmentAsync({ segmentId, updatedData: editedSegment }));
        setIsEditing(false);
      } else {
        await dispatch(addBusinessSegmentAsync(editedSegment))
        .then(() => {
          dispatch(fetchBusinessSegmentsAsync(editedSegment.dashboard));
        });
      }
      if (onSegmentSaved) {
        onSegmentSaved(editedSegment);
      }
    } catch (error) {
      console.error("Failed to save segment:", error);
    }
  };

  const handleDeleteSegment = async () => {
    if (window.confirm("Are you sure you want to delete this segment?")) {
      try {
        await dispatch(deleteBusinessSegmentAsync(segmentId));
      } catch (error) {
        console.error("Failed to delete segment:", error);
      }
    }
  };

  return { handleSaveSegment, handleDeleteSegment };
};

const useInputHandler = (setEditedSegment) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedSegment((prev) => ({ ...prev, [name]: value }));
  };

  return handleInputChange;
};

const BusinessSegment = ({
  segmentId,
  isEditingNew = false,
  onCancel,
  onSegmentSaved,
  businessSegmentName,
  segmentFocusComment,
  activeDashboard,
}) => {

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(isEditingNew);
   // Use utility function for input handling
   
   const [segmentPerformance, setSegmentPerformance] = useState(0);
   const [segmentPerformanceIndicator, setSegmentPerformanceIndicator] = useState(false);

  const [editedSegment, setEditedSegment] = useState({
    segmentId: segmentId || null,  // Keep as null if undefined
    name: businessSegmentName || "New Business Segment",  // Default to empty string if undefined
    focus_comment: segmentFocusComment || "New Segment Focus",  // Default to empty string if undefined
    performance: segmentPerformance || 0,  // Default to 0 if undefined
    performance_indicator: segmentPerformanceIndicator || false,  // Default to false if undefined
    dashboard: activeDashboard ? activeDashboard.id : null,  // Keep as null if undefined
  });

  // Use custom hook for segment actions
  const { handleSaveSegment, handleDeleteSegment } = useSegmentActions(dispatch, segmentId, editedSegment, onSegmentSaved, setIsEditing);

  const handleInputChange = useInputHandler(setEditedSegment);

  const handleEdit = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setAnchorEl(null);
  };

  const handleEscape = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsEditing(false);
      if (onCancel) {
        onCancel();
      }
    }
  }, [onCancel]);

  const handleCancel = (event) => {
    event.stopPropagation();
    setIsEditing(false);
    if (onCancel) {
      onCancel();
    }
  }

  const handleKeyPress = (event, type) => {
    if (event.key === 'Enter') {
      handleSaveSegment(event);
    }
  };


  const handleCalculatedSegmentPerformance = (performance) => {
    if (!isNaN(performance)) {
        setSegmentPerformance(performance);
      if (performance >= 50) {
        setSegmentPerformanceIndicator(true);
      } else {
        setSegmentPerformanceIndicator(false);
      }
    }
  };

  const arrowStyle = {
    transform: segmentPerformanceIndicator ? 'rotate(0deg)' : 'rotate(180deg)',
    fill: segmentPerformanceIndicator ? 'green' : 'red',
  };
  const performanceStyle = {
    color: segmentPerformanceIndicator ? 'green' : 'red',
  }

  const handleMenuOpen = (event) => {
    if (!isEditing){
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = (event) => {
    if (!isEditing){
      event.stopPropagation();
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [isEditing, handleEscape]);

  return (
    <div className={styles.segmentContainer}>
      <div className={styles.segmentHeaderContainer}>
        <Accordion style={{ width: '100%' }}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"   
            >
              <div className={styles.segmentAccordionHeader} style={{ width: '100%' }}>
                {isEditing ? (
                  <>
                  <div className={styles.segmentDeleter}>
                    <CheckIcon onClick={handleSaveSegment} />
                  </div>
                  <div className={styles.segmentDeleter}>
                    <CloseIcon onClick={handleCancel} />
                  </div>
                  </>
                ) : (
                  <div className={styles.segmentDeleter}>
                    <MoreVert onClick={(e) => handleMenuOpen(e)} />
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={(e) => handleMenuClose(e)}
                    >
                      <MenuItem key={1} onClick={(e) => handleEdit(e)} style={{fontSize:'12px',}}>
                        <EditIcon style={{marginRight:'12px', color:'rgba(0, 0, 0, 0.6)', fontSize:'18px',}}/>  
                        Edit
                      </MenuItem>
                      <MenuItem key={2} onClick={handleDeleteSegment} style={{fontSize:'12px',}}>
                        <DeleteForeverIcon style={{marginRight:'12px', color:'rgba(0, 0, 0, 0.6)', fontSize:'18px',}}/>  
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                  )}
              <div className={styles.segment1Name}>
                {isEditing ? (
                  <input 
                    type="text" 
                    value={editedSegment.name} 
                    onChange={handleInputChange} 
                    name="name"
                    onClick={(e) => {e.stopPropagation()}}
                    onKeyDown={(e) => handleKeyPress(e, 'name')}
                    className={styles.inputStyle}
                  />
                ) : (
                  <div className={styles.businessSegmentName}>{businessSegmentName}</div>
                )}
              </div>
              <div className={styles.segment1FocusComment}>
                {isEditing ? (
                  <input 
                    type="text" 
                    value={editedSegment.focus_comment} 
                    onChange={handleInputChange} 
                    name="focus_comment"
                    onClick={(e) => {e.stopPropagation()}}
                    onKeyDown={(e) => handleKeyPress(e, 'comment')}
                    className={styles.inputStyleComment} // updated here
                  />
                ) : (
                  <div className={styles.segment1Focus}>{segmentFocusComment}</div>
                )}
              </div>
              {!isEditing && 
                <div className={styles.segment1Score}>
                <b className={styles.b} style={performanceStyle}>{segmentPerformance}</b>
                <img
                  className={styles.iconlylightarrowDown}
                  alt=""
                  src="/iconlylightarrow--down1.svg"
                  style={arrowStyle}
                />
                </div>
              }
              </div>
          </AccordionSummary>
          <AccordionDetails>
            <SegmentObjectiveContainer
              businessSegmentId={segmentId}
              activeDashboard={activeDashboard}
              onSegmentPerformanceCalculated={(performance) => 
                {handleCalculatedSegmentPerformance(performance);}
              }
            />
            <SegmentDataContainer
              businessSegmentId={segmentId}
              activeDashboard={activeDashboard}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default BusinessSegment;