import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import KPIHeadingContainer from "./KPIHeadingContainer";
import styles from "./css/SegmentDataContainer.module.css";
import AddIcon from '@mui/icons-material/Add';
import {fetchKPIHeadingsAsync} from '../redux/slices/kpiHeadingsSlice';

const SegmentDataContainer = ({ businessSegmentId, activeDashboard}) => {
  const dispatch = useDispatch();
  const { kpiHeadings = [], isLoading, error } = useSelector(state => state.kpiHeadings) || {};
  const filteredKpiHeadings = kpiHeadings.filter(item => item.businesssegment === businessSegmentId);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (businessSegmentId) {
      dispatch(fetchKPIHeadingsAsync(businessSegmentId));
    }
  }, [dispatch, businessSegmentId]);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
  };

  const handleSegmentSaved = () => {
    setIsAdding(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
      <div className={styles.segmentDataContainer}>
        {filteredKpiHeadings && filteredKpiHeadings.map(heading => (
          <KPIHeadingContainer 
            key={heading.id}
            kpiHeadingId = {heading.id}
            kpiHeadingName = {heading.name}
            activeDashboard={activeDashboard}
            kpiHeadingObject = {heading}
          />
        ))}
        {isAdding ? (
          <KPIHeadingContainer
            isAddingNew={true}
            onCancel={handleCancelAdd}
            onSegmentSaved={handleSegmentSaved}
            activeDashboard={activeDashboard}
            businesssegmentId={businessSegmentId}
          />
        ) : (
          <div className={styles.segmentDataContainer_add}>
            <AddIcon onClick={handleAddClick}/>
          </div>
        )}
      </div>
  );
};

export default SegmentDataContainer;
