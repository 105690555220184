import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ObjectiveDetails from "./ObjectiveDetailsComponent";
import SegmentObjectiveStatus from "./SegmentObjectiveStatus";
import styles from "./css/SegmentObjective.module.css";
import Dialog from '@mui/material/Dialog';

export const calculateObjectiveProgress = (objective, allKpis) => {
  const objectiveKpis = allKpis.filter(kpi => objective.kpis.includes(kpi.id));

  const valueToPercentage = (kpi) => {
    if (kpi.kpiData.length === 0) return 0;

    let lastValue = kpi.kpiData[kpi.kpiData.length - 1].value;
    let progress;

    if (kpi.min_value < kpi.max_value) {
      // Normal scenario
      progress = ((lastValue - kpi.min_value) / (kpi.max_value - kpi.min_value)) * 100;
    } else {
      // Reverse scenario
      progress = ((kpi.min_value - lastValue) / (kpi.min_value - kpi.max_value)) * 100;
    }

    return progress;
  };

  const progressValues = objectiveKpis.map(kpi => {
    const kpiDataExists = kpi.kpiData !== undefined;
    return kpiDataExists ? valueToPercentage(kpi) : 0;
  });

  const averageProgress = progressValues.reduce((a, b) => a + b, 0) / progressValues.length;

  return Math.max(Math.min(Math.round(averageProgress), 100), -100); // Clamping between -100 and 100
};

const SegmentObjective = ({ objective }) => {
  const [showDetails, setShowDetails] = useState(false);

  const { kpis: allKpis = [], isLoading, error } = useSelector(state => state.kpis) || {};

  const averageProgress = calculateObjectiveProgress(objective, allKpis); 
  const step = Math.round(averageProgress / 20);
  const hue = step * 24;
  const objectiveStatusColor = `hsl(${hue}, 100%, 50%)`;
 

  const toggleDetails = () => {
    setShowDetails(prev => !prev); // Toggle the showDetails state
  };


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
      return <div>Error: {error}</div>;   
  }

  return (
    <>
      <div className={styles.objectiveContainer} onClick={toggleDetails}>
          <div className={styles.objectiveData}>
            <div className={styles.objectiveDescription}>
              {objective.name}
            </div>
          </div>
          <SegmentObjectiveStatus 
            objectiveStatusColor={objectiveStatusColor}
          />
        
      </div>

      <Dialog
      open={showDetails}
      onClose={()=> {setShowDetails(false);}}
      aria-labelledby="objective-details-dialog"
    >
      <ObjectiveDetails 
        objective={objective}
        onClose={toggleDetails}
      />
    </Dialog>
    </>
  );
};

export default SegmentObjective;